import { Box, Container } from '@mui/material'
import React from 'react'
import Header from './components/header'
import Videocard from './components/video-card'

const Explore = () => {
  return (
   <Container>
    <Box>
    <Box>
    <Header/>
    </Box>
    <Box>
        <Videocard/>
    </Box>

   </Box>   
   </Container>
  )
}

export default  Explore