import React from 'react'
import { useEffect, useState } from "react"; 
import useSound from "use-sound"; // for handling the sound
import qala from "../../../assest/audios/qala.mp3"; // importing the music
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai"; // icons for play and pause
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi"; // icons for next and previous track
import { IconContext } from "react-icons"; // for customazing the icons
import '../styles/player.css'
import PropTypes, { object } from 'prop-types';
const Player = ({mp3audio}) => {


    const [isPlaying, setIsPlaying] = useState(false);
    const [play, { pause, duration, sound }] = useSound(mp3audio);
    const playingButton = () => {
        if (isPlaying) {
          pause(); // this will pause the audio
          setIsPlaying(false);
        } else {
          play(); // this will play the audio
          setIsPlaying(true);
        }
      };
      const [currTime, setCurrTime] = useState({
        min: "",
        sec: "",
      }); // current position of the audio in minutes and seconds
    
      const [seconds, setSeconds] = useState();
      const[min,setmin]=useState(null) // current position of the audio in seconds
      const[sec,setsec]=useState(null) // current position of the audio in seconds
useEffect(()=>{
    const sec = duration / 1000;
    setmin(Math.floor(sec / 60)) 
    setsec(Math.floor(sec % 60))
   
})
      useEffect(() => {
        const interval = setInterval(() => {
          if (sound) {
            setSeconds(sound.seek([])); // setting the seconds state with the current state
            const min = Math.floor(sound.seek([]) / 60);
            const sec = Math.floor(sound.seek([]) % 60);
            setCurrTime({
              min,
              sec,
            });
          }
        }, 1000);
        return () => clearInterval(interval);
      }, [sound]);
  return (
    <div className="component">

         <audio controls style={{width:900}}>
        <source src={mp3audio}  />
        Your browser does not support the audio element.
      </audio>
      </div>
  
  )
}
Player.prototype={
  mp3audio:object
}
export default Player