import songimage from '../assest/images/songimage.jpg';
import StarRateIcon from '@mui/icons-material/StarRate';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';


const SongTab=[
    {
        id:1,
        tittle:'All',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Recommended',
        icon : <StarRateIcon/>,
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            ,
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Popular',
        icon : <WhatshotIcon/>,
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Trending',
        icon : <TrendingUpIcon/>,
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Sing with Artists',
        icon : <MicExternalOnIcon/>,
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Sami song',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
        ],
        
    },
    {
        id:1,
        tittle:'Love songs',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
        ],
        
    },
    {
        id:1,
        tittle:'Pop',
        icon :'' ,
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'India',
        icon :'' ,
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Rock',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Tamil',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Indonesia',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Love Songs',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Korea',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Hindi',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'K-Pop',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Rap',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Country',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Stage & Screen',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Melodies',
        icon : '',
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
    {
        id:1,
        tittle:'Classic',
        icon :'' ,
        songs:[
            {
                id:1,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            },
            {
                id:2,
                src:songimage,
                songName:"sdfghjk",
                username:"ranjithkumar"    
            }
        ],
        
    },
]
export default SongTab;