import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';

export default function SongSelect({ tittle, onchang }) {
  const countries = ['rock', 'melodies', 'classic', 'mass'];
  const data = useSelector((state) => state);

  const [defaultGenres, setDefaultGenres] = React.useState([]);

  React.useEffect(() => {
    const value = data.Upload?.SongMeta || {};
    if (value.genres && value.genres.length > 0) {
      const defaultValue = countries.filter(country => value.genres.includes(country));
      setDefaultGenres(defaultValue);
    }
  }, [data]);

  return (
    <Autocomplete
      multiple={true}
      id="country-select-demo"
      sx={{ width: 500 }}
      options={countries}
      value={defaultGenres}
      onChange={(event, newValue) => {
        setDefaultGenres(newValue);
        onchang(event, newValue);
      }}
      autoHighlight
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          placeholder={tittle}
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
