import songimage from '../assest/images/songimage.jpg'
import peacock from '../assest/images/peacock.jpg'
const SongGroup=[
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,   
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:songimage,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    {
        singImg:peacock,
        groupName:"JUSTMUSIC®️",
        members:1233,
        type:"Global"
    },
    
]

export default SongGroup;