import React, { useEffect, useState } from 'react'
import Heading from './componets/header'
import { Box, Card, Container, Typography,Modal, Button, Divider } from '@mui/material'
import axios from 'axios'
import { url } from '../../config/config'
import { useParams } from 'react-router-dom';
import OfflinePinRoundedIcon from '@mui/icons-material/OfflinePinRounded';
import ErrorDetails from './componets/song-details'
const SongDetails = () => {
    const params = useParams();
 const [karaoke,setKaraoke] = useState([]);
    useEffect(()=>{
       
        const GetSongbook=async()=>{
        
         
         try {  
           
           const res = await axios.get(`${url}/api/Karaoke/getKaraokeByID/${params.id}`);
           setKaraoke(res.data.data)
         console.log(res.data)
          
       } catch (error) {
         console.log(error)
       }
       //    
        }
        GetSongbook()
     
      },[])

      const [logData,setLogData] = useState([])

      useEffect(()=>{
        const GetSongbook=async()=>{
         try {  
          const config={
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
           const res = await axios.get(`${url}/api/Userlog/getUserLogByKaraokeID/${params.id}`,config);
           setLogData(res.data.data)
         console.log(res.data.data)
          
       } catch (error) {
         console.log(error)
       }
       //    
        }
        GetSongbook()
     
      },[])

console.log(logData)
  return (
  <Box sx={{backgroundColor:"#F0F2F4"}}>
    <Container maxWidth='md' sx={{padding:4}}>
        
        <Card sx={{padding:2,display:"flex",minHeight:"100vh",justifyContent:"start",gap:4,paddingTop:'24px'}}>
        <Box>
            <Heading songlyric={karaoke}/>
        </Box>
           <Box>
             <Box sx={{marginTop:'15px'}}>
             <Box>
              {
                karaoke.length !== 0 ?   <Typography  sx={{color:"#2D2867",fontSize:'14px',fontWeight:'700'}}>{ karaoke && karaoke[0]['Artist']}</Typography> : ''
              }
              </Box>
              <Box>
                {
                  karaoke.length !== 0 ?  <Typography  sx={{color:"#2D2867",fontWeight:700,fontSize:'32px'}}> { karaoke && karaoke[0]['Name']}</Typography> : ''
                }
             </Box>
           <Box sx={{display:"flex",alignItems:"center"}}> <Typography  sx={{color:"#2D2867",fontSize:'14px',fontWeight:'400px'}}>Published by<OfflinePinRoundedIcon sx={{color:"#8826FB",fontWeight:700}} fontSize='14px' fontWeight='700' />vijay</Typography></Box>
             </Box>
             <Box sx={{marginTop:2}}>
              <ErrorDetails error={logData}/>
             </Box>
           </Box>
        </Card>
        </Container>
    </Box>
  )
}

export default SongDetails;