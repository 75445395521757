import React, { useEffect, useState } from "react"
import Authmodel from "../components/auth_model"
import { useNavigate } from "react-router-dom"

const PrivateRoutes = ({ children }) => {
    const navigate = useNavigate()
    const [open, setopen] = useState(false)

    useEffect(() => {
        setopen(true)
    }, [children])
    const HandleClose = () => {
        setopen(false)
        navigate('/')
    }

    if (!localStorage.getItem('token')) {
        return (
            <Authmodel open={open} HandleClose={HandleClose} />
        )
    }
    return (
        <div>{children}</div>
    )
}

export default PrivateRoutes