import { Box } from "@mui/material";
import { PiMicrophoneStageFill } from "react-icons/pi";
import { RiMusic2Fill } from "react-icons/ri";
import { MdEmojiEmotions } from "react-icons/md";
const Notify=[
    {
      id:1,
      icon:<PiMicrophoneStageFill color="#6C2EFF" size={50}/>,
      tittle:"Make some music",
      Discrep:"Find the right apps for you. From instruments to vocals, there's something for everyone",
      path:"/apps",
      pathName:'Find your apps'
    },
    {
      id:1,
      icon:<RiMusic2Fill color="#6C2EFF" size={50}/>,
      tittle:"Listen to songs.",
      Discrep:"Enjoy music created on the Smule network, send some love to the recordings you like, or share them with your friends",
      path:"/explore",
      pathName:'Explore Now'
    },
    {
      id:1,
      icon:<MdEmojiEmotions color="#6C2EFF" size={50}/>,
      tittle:"Build Connections.",
      Discrep:"Grow your network by following friends, singers, and people that inspire you.",
      path:"/",
      pathName:'Find your apps'
    },
]

export default Notify;