import { Box, Card,Button, Typography, Avatar, Modal, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import giftImage from './gift-removebg-preview.png'
import CancelIcon from '@mui/icons-material/Cancel';
import { BsCoin } from "react-icons/bs";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { url } from "../../../config/config";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useParams } from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { jwtDecode } from "jwt-decode";
// import CancelIcon from '@mui/icons-material/Cancel';
import axios from "axios";
import TimeAgo from "../timeStmaps";
import Authmodel from "../../../components/auth_model";
import { IoIosArrowBack } from "react-icons/io";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
  };

const ModuleComment = ({funcComment,user,count}) => {
    const [choosed,setChoosed] = useState(true)
    const [commentLike,setCommentLike] = useState(false)
    const [restrict,setRestrict] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const gifts = [
        {
           image : giftImage,
           comment : 'good song'
        },
        {
            image : giftImage,
            comment : 'greate voice'
         },
         {
            image : giftImage,
            comment : 'Fantasitic'
         },{
            image : giftImage,
            comment : 'Super Duper'
         },{
            image : giftImage,
            comment : 'Good'
         },{
            image : giftImage,
            comment : 'Loved it'
         },{
            image : giftImage,
            comment : 'Loved the voice'
         },
    ]
    const params = useParams();
    const [commentData,setCommentData] = useState([])
    useEffect(() => {
        const getComment = async () => {
            const songCollabId = await user[0]._id
            try {
                const res = await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationCommentById/${songCollabId}`);
                console.log(res.data.data)
                setCommentData(res.data.data)
            } catch (error) {
              console.log(error)
            } 
        }
        getComment()
    },[])
    const [comment,setComment] = useState('')
    const handleClick = async () => {
        if(!localStorage.getItem('token')){
            setRestrict(true)
        }else{
            setComment('')
            setCommentLike(false)
            const songCollabId = await user[0]._id
            const data = {
                CommentText : comment,
                SongCollaborationID : songCollabId
            }
            try {
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
                  }
                const res = await axios.post(`${url}/api/SongCollaborationComment/createSongCollaborationComment`,data,config);
                console.log(res.data.data)
               
            } catch (error) {
              console.log(error)
            }
    
            try {
                const res = await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationCommentById/${songCollabId}`);
                console.log(res.data.data)
                setCommentData(res.data.data)
            } catch (error) {
              console.log(error)
            } 
            count(1)
        }
    }
    console.log(commentData)
    const [commentId,setCommentId] = useState('');
    const handleCommentLike = async (val,ind) => {
        if(!localStorage.getItem('token')){
            setRestrict(true)
        }else{
            setCommentId(ind)
            const data ={
                SongCollaborationID : params.id,
                SongCollaborationCommentLikeID : val
            }
              try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
                  }
                const res = await axios.post(`${url}/api/SongCollaborationCommentLike/createSongCollaborationCommentLike`,data,config)
                console.log(res.data.data)
                
              }
              catch (error) {
                 console.log(error)
              }
              const songCollabId = await user[0]._id
              try {
                const res = await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationCommentById/${songCollabId}`);
                console.log(res.data.data)
                setCommentData(res.data.data)
            } catch (error) {
              console.log(error)
            }
            setCommentLike(true)
        }
    }
    console.log(user)

    const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''

    console.log(userTokenData)

    commentData&& commentData.map((val,ind) => (
       val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true ? console.log('true') : console.log('not true')
    ))

    // const handleCommentDelete = (val) => {
    //     try{
    //         const config={
    //             headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
    //           }
    //         const res = await axios.post(`${url}/api/SongCollaborationCommentLike/createSongCollaborationCommentLike`,data,config)
    //         console.log(res.data.data)
    //       }
    //       catch (error) {
    //          console.log(error)
    //       }
    // }
    const HandleClose = () => {
        setRestrict(false)
    }
    const [likeScreen,setLikeScreen] = useState(true)
    const [list,setList] = useState([])
    const handleCommentLikeList = async (val) => {
        if(!localStorage.getItem('token')){
            setRestrict(true)
        }else{
            setLikeScreen(false)
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = await axios.get(`${url}/api/SongCollaborationCommentLike/getSongCollaborationCommentLikeByCommentId/${val}`,config)
                console.log(res.data.data)
                setList(res.data.data)
            }catch(error){
                 console.log(error)
            }
        }
    }
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      console.log(screenWidth)
    return(
        <Box>
    <Box sx={{display:screenWidth > 996 ? 'block' : 'none'}}>
            {
              likeScreen ?  <Card className="commnet-container" sx={{width:'100%',height:'480px',marginTop:'50px'}}>
              <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                  <Box>
                      <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Comments</Box>
                      <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>{commentData.length > 1 ? `${commentData.length} comments` : `${commentData.length} comment`}</Box>
                  </Box>
                  <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:'86%'}}>
              <Box sx={{borderTop:'2px solid #EAE9F0',overflowY:'scroll',height:'100%'}}>
              {
                 commentData && commentData.map((val,ind) => (
                      <Box sx={{display:'flex',justifyContent:'space-between'}}>
                      <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                          <Box>
                          <Avatar alt="loading" sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.users[0]?.ProfilePhoto}/>
                          </Box>
                          <Box>
                          <Box sx={{display:'flex',gap:.5,flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '14px',fontWeight: '700'}}>
                             {val.users[0]?.Name !== '' ? val.users[0]?.Name : 'Default User' }
                              </Box>
                              <Box sx={{display:'flex',flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '16px',fontWeight: '500',lineHeight:'20px'}}>
                             {val?.CommentText}
                             </Box>
                              </Box>
                              <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)',cursor:'pointer'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)',cursor:'pointer'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box onClick={() => handleCommentLikeList(val._id)} sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)',cursor:'pointer'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>{val.NoOfLikes && val.NoOfLikes > 1 ? 'loves' : 'love'}</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box>
                      </Box>
                      {/* <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>love</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box> */}
                      </Box>
                      </Box>
                      <Box>
                          <MoreVertIcon style={{color:'rgb(114, 140, 166)',marginTop:'12px'}}/>
                      </Box>
                  </Box> 
                  ))
              }
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between',borderTop:'2px solid #EAE9F0',padding:'5px'}}>
                    <Box><input value={comment} onChange={(e) => {setComment(e.target.value)}} placeholder="Add a comment" className="commentInput" style={{border:'none',backgroundColor:'rgb(255, 255, 255)',width:'492px',height:'30px'}}/></Box>
                    <Box><Button disabled={comment !== '' ? false : true} sx={{backgroundColor:'rgb(108, 46, 255)',color:'white',borderRadius:'5px',padding:'5px 0',textTransform:'none',fontSize:'15px',fontWeight:700,opacity : comment.length !== 0 ? '' : '0.3',cursor:'pointer'}} onClick={handleClick}>Post</Button></Box>
              </Box>
              </Box>
              <Authmodel open={restrict} HandleClose={HandleClose}/>
          </Card> : <Card className="commnet-container" sx={{width:'480px',height:'480px',marginTop:'50px'}}>
                 <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                      <Box sx={{display:'flex',gap:1}}>
                         <Box onClick={() => {setLikeScreen(true)}} sx={{marginTop:'5px',cursor:'pointer'}}>
                            <IoIosArrowBack color="rgb(192 204 216)" size={27}/>
                         </Box>
                         <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>Comment likes</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{list && list.length > 1 ? `${list.length} loves` : `${list.length} love` } </Box>
                         </Box>
                        </Box> 
                        <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
                 </Box>
                 <Box sx={{borderTop:'2px solid #EAE9F0'}}>
                    {
                      list &&  list.map((data,index) => (
                            <>
                              {
                                data.users && data.users.map((val,ind) => (
                                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex',padding:'10px',gap:2}}>
                                            <Box>
                                                <Avatar sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.ProfilePhoto}/>
                                            </Box>
                                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{val.Name != '' ? val.Name : 'User'}</Box>
                                                <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{val.Email}</Box>
                                            </Box>
                                        </Box>
                                        <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{padding:'10px',marginTop:'8px',cursor:'pointer'}}>
                       <KeyboardArrowRightIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)',cursor:'pointer'}}/>
                    </Box>
                                    </Box>
                                ))
                              }
                            </>
                        ))
                    }
                 </Box>
          </Card>
            }
        </Box> 
        <Modal  style={{display:screenWidth < 996 && screenWidth > 510 ? 'block' : 'none'}}
        open={screenWidth < 996 ? true : false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
         <Box sx={style}>
            {
              likeScreen ?  <Card className="commnet-container" sx={{width:'480px',height:'480px'}}>
              <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                  <Box>
                      <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Comments</Box>
                      <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>{commentData.length > 1 ? `${commentData.length} comments` : `${commentData.length} comment`}</Box>
                  </Box>
                  <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:'86%'}}>
              <Box sx={{borderTop:'2px solid #EAE9F0',overflowY:'scroll',height:'100%'}}>
              {
                 commentData && commentData.map((val,ind) => (
                      <Box sx={{display:'flex',justifyContent:'space-between'}}>
                      <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                          <Box>
                          <Avatar alt="loading" sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.users[0]?.ProfilePhoto}/>
                          </Box>
                          <Box>
                          <Box sx={{display:'flex',gap:.5,flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '14px',fontWeight: '700'}}>
                             {val.users[0]?.Name !== '' ? val.users[0]?.Name : 'Default User' }
                              </Box>
                              <Box sx={{display:'flex',flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '16px',fontWeight: '500',lineHeight:'20px'}}>
                             {val?.CommentText}
                             </Box>
                              </Box>
                              <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)',cursor:'pointer'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)',cursor:'pointer'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box onClick={() => handleCommentLikeList(val._id)} sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)',cursor:'pointer'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>{val.NoOfLikes && val.NoOfLikes > 1 ? 'loves' : 'love'}</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box>
                      </Box>
                      {/* <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>love</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box> */}
                      </Box>
                      </Box>
                      <Box>
                          <MoreVertIcon style={{color:'rgb(114, 140, 166)',marginTop:'12px'}}/>
                      </Box>
                  </Box> 
                  ))
              }
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between',borderTop:'2px solid #EAE9F0',padding:'5px'}}>
                    <Box><input value={comment} onChange={(e) => {setComment(e.target.value)}} placeholder="Add a comment" className="commentInput" style={{border:'none',backgroundColor:'rgb(255, 255, 255)',width:'400px',height:'30px'}}/></Box>
                    <Box><Button disabled={comment !== '' ? false : true} sx={{backgroundColor:'rgb(108, 46, 255)',color:'white',borderRadius:'5px',padding:'5px 0',textTransform:'none',fontSize:'15px',fontWeight:700,opacity : comment.length !== 0 ? '' : '0.3',cursor:'pointer'}} onClick={handleClick}>Post</Button></Box>
              </Box>
              </Box>
              <Authmodel open={restrict} HandleClose={HandleClose}/>
          </Card> : <Card className="commnet-container" sx={{width:'480px',height:'480px'}}>
                 <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                      <Box sx={{display:'flex',gap:1}}>
                         <Box onClick={() => {setLikeScreen(true)}} sx={{marginTop:'5px',cursor:'pointer'}}>
                            <IoIosArrowBack color="rgb(192 204 216)" size={27}/>
                         </Box>
                         <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>Comment likes</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{list && list.length > 1 ? `${list.length} loves` : `${list.length} love` } </Box>
                         </Box>
                        </Box> 
                        <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
                 </Box>
                 <Box sx={{borderTop:'2px solid #EAE9F0'}}>
                    {
                      list &&  list.map((data,index) => (
                            <>
                              {
                                data.users && data.users.map((val,ind) => (
                                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex',padding:'10px',gap:2}}>
                                            <Box>
                                                <Avatar sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.ProfilePhoto}/>
                                            </Box>
                                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{val.Name != '' ? val.Name : 'User'}</Box>
                                                <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{val.Email}</Box>
                                            </Box>
                                        </Box>
                                        <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{padding:'10px',marginTop:'8px',cursor:'pointer'}}>
                       <KeyboardArrowRightIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)',cursor:'pointer'}}/>
                    </Box>
                                    </Box>
                                ))
                              }
                            </>
                        ))
                    }
                 </Box>
          </Card>
            }
        </Box>    
    </Modal>
    <Drawer anchor='bottom'
      open={screenWidth < 510 ? true : false} 
      PaperProps={{
        sx : {
          backgroundColor:'white',
        }
      }}
      >
            {
              likeScreen ?  <Card className="commnet-container" sx={{width:'100%'}}>
              <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                  <Box>
                      <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Comments</Box>
                      <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>{commentData.length > 1 ? `${commentData.length} comments` : `${commentData.length} comment`}</Box>
                  </Box>
                  <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:'86%'}}>
              <Box sx={{borderTop:'2px solid #EAE9F0',overflowY:'scroll',height:'100%'}}>
              {
                 commentData && commentData.map((val,ind) => (
                      <Box sx={{display:'flex',justifyContent:'space-between'}}>
                      <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                          <Box>
                          <Avatar alt="loading" sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.users[0]?.ProfilePhoto}/>
                          </Box>
                          <Box>
                          <Box sx={{display:'flex',gap:.5,flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '14px',fontWeight: '700'}}>
                             {val.users[0]?.Name !== '' ? val.users[0]?.Name : 'Default User' }
                              </Box>
                              <Box sx={{display:'flex',flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '16px',fontWeight: '500',lineHeight:'20px'}}>
                             {val?.CommentText}
                             </Box>
                              </Box>
                              <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)',cursor:'pointer'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)',cursor:'pointer'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box onClick={() => handleCommentLikeList(val._id)} sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)',cursor:'pointer'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>{val.NoOfLikes && val.NoOfLikes > 1 ? 'loves' : 'love'}</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box>
                      </Box>
                      {/* <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>love</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box> */}
                      </Box>
                      </Box>
                      <Box>
                          <MoreVertIcon style={{color:'rgb(114, 140, 166)',marginTop:'12px'}}/>
                      </Box>
                  </Box> 
                  ))
              }
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between',borderTop:'2px solid #EAE9F0',padding:'5px'}}>
                    <Box><input value={comment} onChange={(e) => {setComment(e.target.value)}} placeholder="Add a comment" className="commentInput" style={{border:'none',backgroundColor:'rgb(255, 255, 255)',width:'90%',height:'30px'}}/></Box>
                    <Box><Button disabled={comment !== '' ? false : true} sx={{backgroundColor:'rgb(108, 46, 255)',color:'white',borderRadius:'5px',padding:'5px 0',textTransform:'none',fontSize:'15px',fontWeight:700,opacity : comment.length !== 0 ? '' : '0.3',cursor:'pointer'}} onClick={handleClick}>Post</Button></Box>
              </Box>
              </Box>
              <Authmodel open={restrict} HandleClose={HandleClose}/>
          </Card> : <Card className="commnet-container" sx={{width:'100%'}}>
                 <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                      <Box sx={{display:'flex',gap:1}}>
                         <Box onClick={() => {setLikeScreen(true)}} sx={{marginTop:'5px',cursor:'pointer'}}>
                            <IoIosArrowBack color="rgb(192 204 216)" size={27}/>
                         </Box>
                         <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>Comment likes</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{list && list.length > 1 ? `${list.length} loves` : `${list.length} love` } </Box>
                         </Box>
                        </Box> 
                        <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
                 </Box>
                 <Box sx={{borderTop:'2px solid #EAE9F0'}}>
                    {
                      list &&  list.map((data,index) => (
                            <>
                              {
                                data.users && data.users.map((val,ind) => (
                                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex',padding:'10px',gap:2}}>
                                            <Box>
                                                <Avatar sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.ProfilePhoto}/>
                                            </Box>
                                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{val.Name != '' ? val.Name : 'User'}</Box>
                                                <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{val.Email}</Box>
                                            </Box>
                                        </Box>
                                        <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{padding:'10px',marginTop:'8px',cursor:'pointer'}}>
                       <KeyboardArrowRightIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)',cursor:'pointer'}}/>
                    </Box>
                                    </Box>
                                ))
                              }
                            </>
                        ))
                    }
                 </Box>
          </Card>
            }

      </Drawer>

        </Box>
    )
}

export default ModuleComment