import axios from 'axios'
import React from 'react'
import { useState } from 'react'
const Smulescience = () => {
  const[username,setusername]=useState('')
  const OnchangeFunction=(e)=>{
   setusername(e.target.value)
  }
  const[password,setpassword]=useState('')
  const OnchangeFunction2=(e)=>{
    setpassword(e.target.value)
  }
const HandleSubmit=async()=>{
  const dataObject={
    username:username,
    password:password
  }
  try {
    const response = await axios.post('http://localhost:4000/post',dataObject);
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}
  return (
    <div style={{textAlign:"center"}}>
      <div>username<input type='text' name='username' onChange={OnchangeFunction}></input></div>
      <div>password<input type='password' name='username' onChange={OnchangeFunction2}></input></div>
      <button type='button' onClick={HandleSubmit}>submit</button>
    </div>
  )
}

export default Smulescience