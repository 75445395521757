import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stickerUpload: [],
}

export const uploadstickerSlice = createSlice({
  name: 'uploadSticker',
  initialState,
  reducers: {
    AddSticker: (state, action) => {
    state.stickerUpload =action.payload
    },
   
  }
})

// Action creators are generated for each case reducer function
export const { AddSticker} = uploadstickerSlice.actions

export default uploadstickerSlice.reducer