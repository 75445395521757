import { Box } from "@mui/material";
import React from "react";

const ErrorDetails = ({ error }) => {
  console.log(error);

  return (
    <Box>
      {error.length !== 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
          {error && error.map((val, ind) => (
            <React.Fragment key={ind}>
              {val.LogDetail.map((data, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    wordWrap: 'break-word', 
                    wordBreak: 'break-all', 
                    marginBottom: '8px',
                  }}
                >
                  {data.LogDescription.logMessage}
                </Box>
              ))}
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <Box 
          sx={{ 
            color: '#3f13a8', 
            textAlign: 'center', 
            alignItems: 'center', 
            fontSize: '20px', 
            fontWeight: 'bold', 
            marginTop: '400px' 
          }}
        >
          No error occurred while recording this song collaboration ✌️✌️✌️
        </Box>
      )}
    </Box>
  );
}

export default ErrorDetails;
