import { Box, Typography } from '@mui/material'
import React from 'react'
import BasicCard from '../../../components/card.media'
import mike from '../../../assest/images/mike.png'
import ImageAdd from '../../../data/image-add'
const Cardssong = () => {
  return (
    <Box>
        <Box sx={{textAlign:"center"}}>
            <Typography sx={{fontSize:"32px"}}>Spark-Singer: Karaoke Songs & Videos</Typography>
            <Typography sx={{fontSize:"16px",color:"#8D858C"}}>Sing for yourself. Sing like the stars. All skill levels welcome</Typography>
        </Box>
        <Box sx={{display:"flex",justifyContent:"center",gap:10,flexDirection:{lg:"row",md:'row',xs:"column"}}}>
        {
           ImageAdd && ImageAdd.map((data,index)=>(
               
        <BasicCard
                    video=
                    {<img style={{width:"200px"}} src={data.src}></img>}
                    white='black'
                    tittle1={
                        data.tittle1
                    }
                    tittle2={
                        data.tittle2
                    }
                    tittle3={
                        data.tittle3
                    }
                />
      
            
            
            
            
            ))
        }
          </Box>
    </Box>
    
  )
}

export default Cardssong