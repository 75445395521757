import React, { useEffect, useState } from 'react'
import Header from './componenets/header'
import { Box, Card, Container, Typography,Modal, Button, Divider } from '@mui/material'
import Footer from './componenets/footer'
import BasicTabs from './componenets/song-tabs'
import OfflinePinRoundedIcon from '@mui/icons-material/OfflinePinRounded';
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { url } from '../../config/config';
import { FaEdit } from "react-icons/fa";
import { jwtDecode } from 'jwt-decode';
import { GiSaveArrow } from "react-icons/gi";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { GiMicrophone } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import { FaCamera } from "react-icons/fa";
import { useSelector } from 'react-redux'
import guna from './componenets/guna.jpg'
import { useDispatch } from 'react-redux';
import { KaraokeEdit, KaraokeTiming } from '../../features/addlyric/addLyric'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const Songs = () => {
  let location = useLocation();
  const[karaokeLyric,setKaraokeLyric]=useState([])
  const [karaoke,setKaraoke] = useState([])
  const params = useParams();
  const navigate = useNavigate();
  const [coverImg,setCoverImg] = useState(null)
  console.log(params.id)
  const value = useSelector((data) => data)
  console.log(value)
  const [karaokeTitle,setKaraokeTitle] = useState(false)
  const [karaokeSinger,setKarokeSinger] = useState(false)
  const [titleValue,setTitleValue] = useState('')
  const [artistValue,setArtistValue] = useState('')
  const testArrey = [
    {
      value : ['vijay'],
      num : {
        vijay : 'true'
      }
    }
  ]

  
   useEffect(()=>{
       
    const GetSongbook=async()=>{
    
     
     try {  
       
       const res = await axios.get(`${url}/api/Karaoke/getKaraokeByID/${params.id}`);
       setKaraoke(res.data.data)
     console.log(res.data)
      
   } catch (error) {
     console.log(error)
   }
   //    
    }
    GetSongbook()
 
  },[])

  const dispatch = useDispatch()
  console.log(karaoke)
  const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : '';
console.log(userTokenData)
const [editKaraoke,setEditKaraoke] = useState(false)
const [open,setOpen] = useState(false)
const handleClose = () => {
  setOpen(false)
}
const handleDelete = async () => {
   try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.delete(`${url}/api/Karaoke/deleteKaraoke/${params.id}`,config)
      console.log(res.data.data)
      if(res.data.data !== ''){
        navigate('/song')
      }
    } catch (error) {
      console.log(error)
    }
    setOpen(false)
}
const url1 = coverImg != null ? URL.createObjectURL(coverImg) : '';
console.log(url1)
const handleEditTiming = async () => {
   const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
    const data ={
      lyricTiming : karaokeDetails,
      lyricColor : karaoke && karaoke[0].Color,
      karaokeDetails : {
        artist : karaoke && karaoke[0].Artist,
        genres : karaoke && karaoke[0].Genre,
        title : karaoke && karaoke[0].Name,
        tags : karaoke && karaoke[0].Tags,
        url : url1 !== '' ? url1 : karaoke && karaoke[0].CoverImageURL
      },
      karaokeUrl : {
        url : karaoke && karaoke[0].KaraokeUrl
      }
    }
    const value = {
      edited : true
    }
    dispatch(KaraokeEdit(value))
    dispatch(KaraokeTiming(data))
    navigate('/uploads/songtiming')
    localStorage.setItem('karaokeId',params.id)
}
const handleEditLyric = async () => {
  const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
   const data ={
     lyricTiming : karaokeDetails,
     lyricColor : karaoke && karaoke[0].Color,
     karaokeDetails : {
       artist : karaoke && karaoke[0].Artist,
       genres : karaoke && karaoke[0].Genre,
       title : karaoke && karaoke[0].Name,
       tags : karaoke && karaoke[0].Tags,
       url : karaoke && karaoke[0].CoverImageURL
     },
     karaokeUrl : {
       url : karaoke && karaoke[0].KaraokeUrl
     }
   }
   const value = {
    edited : true
  }
  dispatch(KaraokeEdit(value))
   dispatch(KaraokeTiming(data))
   localStorage.setItem('karaokeId',params.id)
   navigate('/uploads/songlyrics')
}
const handleEditStructure = async () => {
  const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
   const data ={
     lyricTiming : karaokeDetails,
     lyricColor : karaoke && karaoke[0].Color,
     karaokeDetails : {
       artist : karaoke && karaoke[0].Artist,
       genres : karaoke && karaoke[0].Genre,
       title : karaoke && karaoke[0].Name,
       tags : karaoke && karaoke[0].Tags,
       url : karaoke && karaoke[0].CoverImageURL
     },
     karaokeUrl : {
       url : karaoke && karaoke[0].KaraokeUrl
     }
   }
   const value = {
    edited : true
  }
  dispatch(KaraokeEdit(value))
   dispatch(KaraokeTiming(data))
   localStorage.setItem('karaokeId',params.id)
   navigate('/uploads/songstructure')
}

const handleImageChange = async (e) => {
  setCoverImg(e.target.files[0])
  testArrey[0].value.unshift(url1)
  // const image = await e.target.files[0]
  // const url1 = await URL.createObjectURL(image)
  // const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
  // const data ={
  //   lyricTiming : karaokeDetails,
  //   lyricColor : karaoke && karaoke[0].Color,
  //   karaokeDetails : {
  //     artist : karaoke && karaoke[0].Artist,
  //     genres : karaoke && karaoke[0].Genre,
  //     title : karaoke && karaoke[0].Name,
  //     tags : karaoke && karaoke[0].Tags,
  //     url :  karaoke && karaoke[0].CoverImageURL
  //   },
  //   karaokeUrl : {
  //     url : karaoke && karaoke[0].KaraokeUrl
  //   }
  // }
  // dispatch(KaraokeTiming(data))
}
console.log(coverImg)
const [saveConfrim,setSaveConfrim] = useState(false)
const hadldeEditKaraoke =  async () => {
   const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics
   const data ={
    lyricTiming : karaokeDetails,
    lyricColor : karaoke && karaoke[0].Color,
    karaokeDetails : {
      artist : karaoke && artistValue !== '' ? artistValue : karaoke[0].Artist,
      genres : karaoke && karaoke[0].Genre,
      title : karaoke && titleValue !== '' ? titleValue : karaoke[0].Name,
      tags : karaoke && karaoke[0].Tags,
      url :  karaoke && karaoke[0].CoverImageURL
    },
    karaokeUrl : {
      url : karaoke && karaoke[0].KaraokeUrl
    }
  }
  dispatch(KaraokeTiming(data))
  setSaveConfrim(true)
}
const [editConfrim,setEditConfrim] = useState(false)
const handleEditCancel = () => {  
  // const value = {
  //   edited : true
  // }
  // dispatch(KaraokeEdit(value))
  setEditConfrim(true)
}

const handleEditClose = () => {
  setEditConfrim(false)
}

const handleConfrim = () => {
  const data = {
    edited : false
  }
  dispatch(KaraokeEdit(data))
  value&&value?.Upload?.KaraokeEdited[0]?.isTrue && editKaraoke === false ? navigate(-1) : setEditKaraoke(false)
  setEditConfrim(false)
}

const handleSaveConfrim = async () => {
  const response = await fetch(url1);
  const blob = await response.blob();
  console.log(blob)
  const formData = new FormData();
  if(coverImg !== null) {
    formData.append('cover',blob)
  }
  formData.append('SongData', JSON.stringify(value.Upload));

  try{
    const config={
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
    const res = await axios.patch(`${url}/api/Karaoke/updateKaraoke/${params.id}`,formData,config)
    console.log(res)
  }catch (error){
      console.log(error)
  }
  setSaveConfrim(false)
  window.location.reload()
}

const handleEdit = () => {
  setEditKaraoke(true)
  const data = {
    edited : true
  }
  dispatch(KaraokeEdit(data))
}
testArrey[0].value.unshift(url1)
console.log(testArrey)

const [screenWidth,setScreenWidth] = useState(window.innerWidth);
useEffect(() => {
 const handleResize = () => {
   setScreenWidth(window.innerWidth);
 };

 window.addEventListener('resize', handleResize);

 // Cleanup the event listener on component unmount
 return () => {
   window.removeEventListener('resize', handleResize);
 };
}, []);

  return (
  <Box sx={{backgroundColor:"#F0F2F4"}}>
    {
        value && value?.Upload?.KaraokeEdited[0].isTrue || editKaraoke ?  <Container maxWidth='md' >
      <Box sx={{display:'flex',flexDirection:'column',gap:2}}>
        {
          karaoke && karaoke.map((val,ind) => (
            <>
            <Box>
          <Card sx={{width:"1000px"}}>
            <Box sx={{display:'flex',justifyContent:'space-between',padding:'0px'}}>
              <Box>
                <Box sx={{display:'flex',gap:2,width:"500px"}}>
                <Box>
              <Box><img src={coverImg != null ? url1 : val.CoverImageURL} style={{width:'190px',height:'190px'}}/></Box>
              <input  onChange={handleImageChange} type="file" name="file" id="file" className="inputfile"/>
               <label style={{opacity:1,background:'rgba(255, 255, 255, 0.5)',padding:'10px',borderRadius:'50%',display:"flex",marginLeft:140}} for="file"><FaCamera color='red'/></label>
            </Box>
                  <Box>
                    <Card sx={{background:"#CEF3DE",marginTop:5,width:"680px"}}>
                    <Box sx={{display:'flex'}}>
                      {
                        karaokeTitle ?  <Box>
                          <input onChange={(e) => {setTitleValue(e.target.value)}} style={{display:"flex",border:'none',boxShadow:'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',height:'30px',width:'430px',marginTop:'15px'}}  type='text'  defaultValue={val.Name}/>
                          </Box> : <Box sx={{fontSize:'30px',fontWeight:'bold',color:'rgb(18, 11, 39)',overflow:'hidden'}}>{val.Name}</Box> 
                      }
                      <Button sx={{backgroundColor:"#04AA6D",color:"black",display:'flex',border:'black',padding:'10px',gap:2,borderRadius:'5px',left:0,cursor:'pointer'}} onClick={() => setKaraokeTitle(true)}>Edit</Button>
                    </Box>  
                    <Box sx={{display:'flex'}}>
                      { 
                      karaokeSinger ?<Box>
                        <input onChange={(e) => {setArtistValue(e.target.value)}}  style={{display:"flex",border:'none',boxShadow:'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',height:'30px',width:'430px',marginTop:'15px'}}  type='text'  defaultValue={val.Artist}/>
                        </Box> : <Box sx={{fontSize:'20px',fontWeight:400,color:'rgb(149, 150, 150)'}}>{val.Artist}</Box>
                      }
                      <Button sx={{display:'flex',backgroundColor:"#04AA6D",color:"black",padding:'10px',gap:2,borderRadius:'5px',marginBottom:'15px',cursor:'pointer'}} onClick={() => setKarokeSinger(true)}>Edit</Button>
                    </Box>
                    </Card>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{display:'flex',color:"black",backgroundColor:"#008CBA",padding:'10px',gap:2,borderRadius:'5px',marginTop:'40px',cursor:'pointer'}}>
                  <Box><GiSaveArrow color='black' size={20}/></Box>
                  <Box sx={{color:"black",backgroundColor:"#008CBA"}} onClick={hadldeEditKaraoke}>Save</Box>
                </Box>
                <Box sx={{display:'flex',color:"black",backgroundColor:"#e7e7e7",padding:'10px',marginTop:'15px',gap:2,borderRadius:'5px',cursor:'pointer'}} onClick={handleEditCancel}>
                  <Box><ClearIcon size={20}/></Box>
                  <Box sx={{backgroundColor:"#e7e7e7"}}>Cancel</Box>
                </Box>
                <Box sx={{display:'flex',marginTop:'40px',backgroundColor:'#C32516',color:'black'}} onClick={() => {setOpen(true)}}>
                  <Box><DeleteOutlineIcon style={{color:"black"}}/></Box>
                  <Box sx={{fontSize:'14px',marginTop:'4px',cursor:'pointer'}}>Delete song</Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
        <Box>
          <Box sx={{display:'flex',gap:2}}>
            <Box>
              <Card sx={{width:'552px'}}>
                <Box sx={{display:'flex',gap:7,color:'rgb(108, 100, 244)',textTransform:'capitalize',fontSize:'14px',color:'rgb(108, 100, 244)',fontWeight:'bold',padding:'10px',marginTop:'10px'}}>
                  <Box sx={{textTransform:'capitalize',cursor:'pointer'}} onClick={handleEditTiming}>Edit Timimg</Box>
                  <Box sx={{textTransform:'capitalize',cursor:'pointer'}} onClick={handleEditLyric}>Edit Lyrics + Parts</Box>
                  <Box sx={{textTransform:'capitalize',cursor:'pointer'}} onClick={handleEditStructure}>Edit Structure</Box>
                </Box>
                {
                  val.KaraokeLyrics && val.KaraokeLyrics.map((lyricData,lyricIndex) => (
                    <Box sx={{display:'flex',gap:10,padding:'5px 10px',marginTop:'10px'}}>
                    <Box sx={{color:'rgb(170, 170, 170)',fontSize:'21px'}}>{lyricData.convertedTime}</Box>
                    <Box sx={{fontSize:'21px',color:'rgb(34, 34, 34)',fontFamily:'sans-serif'}}>{lyricData.Sentence}</Box>
                  </Box>
                  ))
                }
              </Card>
            </Box>
            <Box>
              <Box sx={{marginBottom:'10px'}}><Card>
                <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px',gap:9,backgroundColor:'#CEF3DE'}}>
                  <Box sx={{color:'black',fontSize:'21px',fontWeight:'bold'}}>Language</Box>
                  <Box sx={{textAlign:'left',color:'rgb(108, 100, 244)',fontWeight:'bold',marginTop:'4px',cursor:"pointer"}}>Edit</Box>
                  </Box>
                  <Divider/>
                  <Box sx={{width:'66px',height:'33px',background:'#04AA6D',textAlign:'center',margin:'10px',borderRadius:'5px',color:'black',fontWeight:"bold",alignItems:'center',cursor:"pointer"}}>English</Box>
                  </Card></Box>
                  <Box sx={{marginBottom:'10px'}}><Card>
                <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px',gap:9,backgroundColor:'#CEF3DE'}}>
                  <Box sx={{color:'black',fontSize:'21px',fontWeight:'bold'}}>Genre</Box>
                  <Box sx={{textAlign:'left',color:'rgb(108, 100, 244)',fontWeight:'bold',marginTop:'4px',cursor:"pointer"}}>Edit</Box>
                  </Box>
                  <Divider/>
                  <Box sx={{width:'90px',height:'33px',background:'#04AA6D',textAlign:'center',margin:'10px',borderRadius:'5px',color:'black',fontWeight:"bold",alignItems:'center',cursor:"pointer"}}>{'Melodies'}</Box>
                  </Card></Box>
                  <Box><Card>
                <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px',gap:9,backgroundColor:'#CEF3DE'}}>
                  <Box sx={{color:'black',fontSize:'21px',fontWeight:'bold'}}>Other</Box>
                  <Box sx={{textAlign:'left',color:'rgb(108, 100, 244)',fontWeight:'bold',marginTop:'4px',cursor:"pointer"}}>Edit</Box>
                  </Box>
                  <Divider/>
                  <Box sx={{width:'90px',height:'33px',background:'#04AA6D',textAlign:'center',margin:'10px',borderRadius:'5px',color:'black',fontWeight:"bold",alignItems:'center',cursor:"pointer"}}>{'Melodies'}</Box>
                  </Card></Box>
            </Box>
          </Box>
        </Box></>
          ))
        }  
      </Box>
    </Container> :  <Container maxWidth={screenWidth < 960 ? 'xl' : 'md'} sx={{padding: screenWidth < 960 ? 0 : 5}}>
        
        <Card sx={{padding:2,display:"flex",minHeight:"100vh",justifyContent:"start",gap:4,paddingTop:'24px'}}>
        <Box>
            <Header songlyric={karaoke}/>
        </Box>
           <Box sx={{width:'100%'}}>
             <Box sx={{marginTop:'15px'}}>
             <Box>
              {
                karaoke.length !== 0 ?   <Typography  sx={{color:"#2D2867",fontSize:'14px',fontWeight:'700'}}>{ karaoke && karaoke[0]['Artist']}</Typography> : ''
              }
              </Box>
              <Box>
                {
                  karaoke.length !== 0 ?  <Typography  sx={{color:"#2D2867",fontWeight:700,fontSize:'32px'}}> { karaoke && karaoke[0]['Name']}</Typography> : ''
                }
             </Box>
           <Box sx={{display:"flex",alignItems:"center"}}> <Typography  sx={{color:"#2D2867",fontSize:'14px',fontWeight:'400px'}}>Published by<OfflinePinRoundedIcon sx={{color:"#8826FB",fontWeight:700}} fontSize='14px' fontWeight='700' />{karaoke && karaoke[0]?.userData[0]?.Name !== '' ?karaoke[0]?.userData[0]?.Name : 'user' }</Typography></Box>
             </Box>
             <Box sx={{marginTop:2}}>
              <BasicTabs  songlyric={karaoke}/>
             </Box>
           </Box>
           {
            userTokenData.userId === karaoke[0]?.userData[0]?._id ?  <Box  sx={{background:'rgb(228 233 238)',width:'56px',height:'50px',textAlign:'center',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',margin:'20px',marginTop:'60px'}} onClick={handleEdit}>
            <Box>
              <FaEdit color='rgb(114 140 166)'/>
            </Box>
           </Box> : ''
           }
           {/* {
            karaoke[0]?.AnimatedVideoURL !== undefined && karaoke[0]?.AnimatedVideoURL !== null ? <Box sx={{padding:'10px',background:'#6c2eff',color:'white',fontSize:'14px',fontWeight:700,height:'40px',width:'content-fit'}}>Animation Preview</Box> : ''
           } */}
        </Card>
        </Container>
    }
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
         <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Box sx={{background:'rgb(187 187 187)',padding:'10px',borderRadius:'50%',width:'50px',height:'50px',textAlign:'center',display:'flex',alignItems:'center'}}>
          <Box sx={{alignItems:'center',textAlign:'center'}}><GiMicrophone color='white' size={40} style={{marginTop:'7px'}}/></Box>
          </Box>
         </Box>
         <Box sx={{textAlign:'center',fontSize:'1em',color:'rgb(61, 56, 57)',fontWeight:700,marginTop:'20px'}}>Delete Song ?</Box>
         <Box sx={{color:'rgb(138, 135, 136)',fontSize:'14px',fontWeight:400,textAlign:'center',marginTop:'10px'}}>Your song will be deleted from your profile and the songbook</Box>
         <Box sx={{textAlign:'center',display:'flex',gap:2,alignItems:'center',justifyContent:'center',marginTop:'20px'}}>
            <Button sx={{background:'rgb(240, 240, 240)',padding:'10px',color:'black',fontSize:'13px',fontWeight:'bold'}} onClick={() => setOpen(false)}>Cancel</Button>
            <Button sx={{background:'rgb(64, 52, 240)',padding:'10px',color:'white',fontSize:'13px',fontWeight:'bold'}} onClick={handleDelete}>Delete Song</Button>
         </Box>
        </Box>
      </Modal>
      <Modal
  open={editConfrim}
  onClose={handleEditClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
    Changes that you made may not be saved.
    </Typography>
  <Button onClick={handleConfrim}>Leave</Button>
  </Box>
</Modal>
<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
         <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Box sx={{background:'rgb(187 187 187)',padding:'10px',borderRadius:'50%',width:'50px',height:'50px',textAlign:'center',display:'flex',alignItems:'center'}}>
          <Box sx={{alignItems:'center',textAlign:'center'}}><GiMicrophone color='white' size={40} style={{marginTop:'7px'}}/></Box>
          </Box>
         </Box>
         <Box sx={{textAlign:'center',fontSize:'1em',color:'rgb(61, 56, 57)',fontWeight:700,marginTop:'20px'}}>Delete Song ?</Box>
         <Box sx={{color:'rgb(138, 135, 136)',fontSize:'14px',fontWeight:400,textAlign:'center',marginTop:'10px'}}>Your song will be deleted from your profile and the songbook</Box>
         <Box sx={{textAlign:'center',display:'flex',gap:2,alignItems:'center',justifyContent:'center',marginTop:'20px'}}>
            <Button sx={{background:'rgb(240, 240, 240)',padding:'10px',color:'black',fontSize:'13px',fontWeight:'bold'}} onClick={() => setOpen(false)}>Cancel</Button>
            <Button sx={{background:'rgb(64, 52, 240)',padding:'10px',color:'white',fontSize:'13px',fontWeight:'bold'}} onClick={handleDelete}>Delete Song</Button>
         </Box>
        </Box>
      </Modal>
      <Modal
  open={saveConfrim}
  onClose={handleSaveConfrim}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
    Your changes will appear shortly in Spark singer. If you have the app open on your device, quit and relaunch it to see your changes.
    </Typography>
  <Button onClick={handleSaveConfrim}>confrim</Button>
  </Box>
</Modal>
  </Box>
  )
}

export default Songs