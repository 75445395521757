import { Box, Typography } from '@mui/material'
import React from 'react'
import Appcard from '../../../components/app-card'
import AppleIcon from '@mui/icons-material/Apple';
const Header = () => {
  return (
    
    <Box sx={{textAlign:"center"}}>
       <Box sx={{display:"flex",flexDirection:"column",textAlign:"center",gap:1,padding:5}}>
       <Typography sx={{fontSize:"30px"}}>Spark:Karaoke Songs & Videos</Typography>
        <Typography sx={{fontSize:"19px"}}>Spark-Singing karaoke solo, with friends,  songs with lyrics and more!</Typography>
       </Box>
       <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",gap:{lg:7,md:5,xs:3},flexDirection:{xs:"column",lg:"row",md:"row"}}}>
        <Appcard 
         icon={<AppleIcon fontSize='large'/>}
         tittle1='Download on the'
         tittle2='App Store'
        />
        <Appcard 
         icon={<AppleIcon fontSize='large'/>}
         tittle1='Download on the'
         tittle2='Google Play'
        />
        <Appcard 
         icon={<AppleIcon fontSize='large'/>}
         tittle1='Download on the'
         tittle2='App Gallery'
        />
        
       </Box>
       <Typography sx={{margin:2}} >Let's Spark Music Singer™</Typography>
    </Box>

  )
}

export default Header