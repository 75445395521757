import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = () => {
  return (
    
    <Box sx={{textAlign:"center"}}>
        <Box>
            <Typography sx={{fontSize:"2em",color:"#662867"}}>Explore</Typography>
            <Typography sx={{color:"#728CA6"}}>Discover new music, performances, groups, and challenges. Join and</Typography>
            <Typography sx={{color:"#728CA6"}}>sing your favorite songs!</Typography>
        </Box>
    </Box>
  )
}

export default Header