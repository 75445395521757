import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const Startpage = () => {
  return (
    
    <Box sx={{textAlign:"center"}}>
        <Typography sx={{fontSize:'32px'}}>Online-Only Offer: Up to 90% off on VIP</Typography>
        <Typography sx={{fontSize:'20px'}}>Unlimited songs and solos, access on both ios and android, and no ads.</Typography>
        <Typography sx={{paddingTop:7}}><Button sx={{width:"316px",height:"56px",backgroundColor:"#FD286E"}} variant="contained">Get started</Button></Typography>
    </Box>
  )
}

export default Startpage