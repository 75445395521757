import React from 'react'
import { useSelector } from 'react-redux'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Autocomplete, Box, Button, Card, Checkbox, Container, InputAdornment, TextField, Typography } from '@mui/material'

const Addinfo = () => {

  const sticker = useSelector((state) => state)
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
  ];
  return (
    <Container maxWidth='lg' sx={{textAlign:"center"}}>
    <Card sx={{display:"flex",flexDirection:"column",alignItems:"center",margin:10,padding:7}}>
      <Box><Typography fontSize={40}>Add Info</Typography></Box>
         <Box sx={{display:"flex",gap:2}}>
           <Box>
            <img src={sticker.Uploads.stickerUpload.url} style={{width:200}}></img>
           </Box>
           <Box>
               <Box><TextField
          
          id="outlined-start-adornment"
          sx={{ m: 1, width: '55ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">Sticker name:</InputAdornment>,
          }}
        /></Box>
         <Box> <Autocomplete
      
      id="combo-box-demo"
      options={top100Films}
      sx={{ m: 1, width: '55ch' }}
      renderInput={(params) => <TextField {...params} label="Movie" />}
    /></Box>
       <Box sx={{display:"flex",alignItems:"center"}}>
        <Box><Checkbox   /></Box>

        <Box><Typography fontSize={18}>I agree that Smule uses my art for commercial purposes</Typography></Box>
       </Box>
       <Box sx={{display:"flex",alignItems:"center"}}>
        <Box><Checkbox   /></Box>

        <Box><Typography fontSize={18} width={500} sx={{textAlign:"start"}}>
       I hereby confirm that this is my original artwork and I have the rights to license them to Smule.</Typography></Box>
       </Box>
       <Box pt={6}>
        <Button variant='contained' sx={{width:200,bgcolor:"#67C646",color:"black",textAlign:"center"}}>Upload Sticker</Button>
       </Box>
           </Box>
          
         </Box>
    </Card>
 </Container>
  )
}

export default Addinfo