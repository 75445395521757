import React, { useEffect } from 'react';
import { ImPodcast } from 'react-icons/im'
import { useDispatch, useSelector } from 'react-redux'
import Player from './player'
import { useNavigate } from 'react-router-dom'
import { useState,useRef } from 'react'
import { AddTime, ResetLyricTiming } from '../../../features/addlyric/addLyric'
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import { Box, Button, Card, Container, IconButton, Typography, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import '../styles/lyricTiming.css';
import { editLyricinTiming } from '../../../features/addlyric/addLyric'
import { upload } from '@testing-library/user-event/dist/upload';
import { CheckBoxOutlineBlankRounded, Grid3x3 } from '@mui/icons-material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ReplayIcon from '@mui/icons-material/Replay';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius:'5px',
  boxShadow: 24,
  p: 4,
};
const Songtiming = () => {
  const audio = useSelector((state) => state)
 const [edit,setEdit] = useState(false)
 const [hello,setHello] = useState(true)
 const [thankYou,setThankYou] = useState(true)
 const [resetIndex,setResetIndex] = useState('')
 const [play,setPlay] = useState(false)
 const scrollRef  = useRef();
 
  const audioSrc = audio.Upload.songUpload
  console.log(audio)
    const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
const dispatch =useDispatch()
  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const str_pad_left = (string,pad,length) => {
    return (new Array(length+1).join(pad)+string).slice(-length);
  }
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime - minutes * 60);
  const currentTimeVaue = str_pad_left(minutes,'0',2) + ':' + str_pad_left(seconds,'0',2);
  // Minutes and seconds conversion

const HandleTiming=(index,bool)=>{
const obj={
  index:index,
  time: index === 0 ? 0  : Math.floor(currentTime),
  convertedTime : index === 0 ? '00:00' : currentTimeVaue,
  selected:bool,
  audioDuration : Math.floor(audioRef.current.duration)
}
  dispatch(AddTime(obj))
  console.log(obj)
  console.log(index)
}

const navigate=useNavigate()

const [isPlaying, setIsPlaying] = useState(false);

const playPause = () => {
  if (isPlaying) {
    audioRef.current.pause();
  } else {
    audioRef.current.play();
  }
  setIsPlaying(!isPlaying);
};
const [artists, setArtists] = useState([]);
const rewind = () => {
  setArtists([
    ...artists,
    { id:1, name: audioRef.current.currentTime }
  ]); ; // Rewind by 10 seconds
};

const fastForward = () => {
  audioRef.current.currentTime += 1; // Fast forward by 10 seconds
};
const [editIndex,setEditIndex] = useState('')
const handleEditclick = (ind) => {
  setEdit(true)
  setEditIndex(ind)
}   
const [editValues,setEditValues] = useState('')

audio.Upload.songLyrics.map((data) => (
  console.log(data)
))

const handleEditlyric = (val) => {
  const data = {
    index : val,
    lyric : editValues
  }
   dispatch(editLyricinTiming(data))
   setEdit(false)
}

console.log(audio.Upload.TextfileLyrics)

const [activeLineIndex, setActiveLineIndex] = useState(0);
  const listRef = useRef(null);
  // useEffect(() => {
  //   // Find the index of the lyric line based on currentAudioTime
  //   const newIndex = audio.Upload.TextfileLyrics.findIndex(line => line.startTime == Math.floor(currentTime));
  //   console.log(newIndex)
  //   setActiveLineIndex(newIndex === -1 ? '' : newIndex);
  //   // if (newIndex !== -1) {
  //   //   // Scroll to the active lyric line
  //   //   listRef.current.scrollTo({
  //   //     top: listRef.current.children[newIndex].offsetTop,
  //   //     behavior: 'smooth'
  //   //   });
  //   // }
  // }, [currentTime, audio.Upload.TextfileLyrics]);

  // console.log(activeLineIndex)

  // useEffect(() => {
  //     listRef.current.children[activeLineIndex].scrollIntoView({ behavior: 'smooth' });
  // },[activeLineIndex])

  const [indVal,setIndVal] = useState(0)

  // useEffect ( () => {
  //     const value =  audio.Upload.TextfileLyrics.findIndex(line => line.startTime == Math.floor(currentTime))
  //     setIndVal(value)

  // },[currentTime])
  //  useEffect(() => {
   
  //     listRef.current.children[indVal].scrollIntoView({ behavior: 'smooth' });
  // },[indVal])

console.log(indVal)
const [open,setOpen] = useState(false)
const handleReview = () => {
  audioRef.current.value = 0;
  audioRef.current.play()
}

const handleClose = () => setOpen(false)

const handleModuleOpen = (val) => {
  setResetIndex(val)
  setOpen(true)
}

let value = 0

console.log(value ? 'vijay' : 'madhan')


const audioValue = document.getElementById('audio');

const hanldeResetTiming = async () => {
    const data = {
      timingReset : resetIndex
    }
    dispatch(ResetLyricTiming(data))
    setOpen(false)
    const value = await resetIndex - 1
    audioRef.current.currentTime = audio.Upload.TextfileLyrics[value].StartTime
}
const [index,setIndex] = useState('')
useEffect(() => {
  const output = audio.Upload.TextfileLyrics && audio.Upload.TextfileLyrics.findIndex(
    item => item.StartTime <= Math.floor(currentTime) && item.EndTime > Math.floor(currentTime)
)
setIndex(output)
 
},[currentTime])

useEffect(() => {
  if(index !== -1){
    scrollRef.current.scrollTop += 100
  }
},[index])

  return (
        <Box sx={{textAlign:"center",height:"100vh"}}>
        <Container>
        <Card sx={{padding:2}}>
            <Box>
              <Box><Typography fontSize={40}>Lyrics Timing</Typography></Box>
              <Box><Typography fontSize={20}>Double the fun by arranging your lyrics for singers</Typography></Box>
            </Box>
            <Box>
            <audio id='audio' style={{width:800}} controlsList='nodownload noplaybackrate'  controls ref={audioRef} onTimeUpdate={handleTimeUpdate}>        <source src={audioSrc.url}  />
            </audio>
            </Box>
            <Box sx={{display:'flex',justifyContent:'center',marginBottom:'10px'}}>
              {
                play ?  <IconButton >
                  <Button onClick={()=>{
                  setPlay(false)
                  audioRef.current.pause()
                }} variant='contained'>Pause</Button>
                </IconButton> :  <IconButton >
              <Button onClick={()=>{
                  setPlay(true)
                   audioRef.current.play()  
            }} variant='contained'>Play</Button>
            </IconButton>
              }
            <IconButton onClick={()=>{
              navigate('/uploads/songstructure')
            }}>
              <Button variant='contained'>continue</Button>
            </IconButton>
          </Box>
      <Box ref={scrollRef} sx={{overflowY:"scroll",height:350,textAlign:"center",background:'black'}}>
      {
             audio.Upload.TextfileLyrics==0  ?
          <Box sx={{mt:10}}>
         {
             audio.Upload.songLyrics&&     audio.Upload
             . songLyrics.map((data,index)=>(
             
                <Grid container sx={{boxShadow: data. time?'':1,cursor:'pointer'}} className='lyric-timimg-grid'>
                    <Grid item md={1}>
                      <Box sx={{display:'flex',padding:'10px',gap:'5px'}}>
                      <Box sx={{color:"red"}}><VolumeDownIcon fontSize='large'/></Box>
           { data. startTime != '' ? <Box sx={{fontSize:25,textAlign:'center'}}>{data.startTime}</Box>:<Box sx={{fontSize:20,textAlign:'center'}}>{Math.round(currentTime/60)}</Box>}
                      </Box>
                    </Grid>
                    <Grid item md={8}>
                    <Box sx={{textAlign:"left"}}>
                      {
                         edit ? <input style={{height:'30px',width:'100%',fontSize:'20px',border:'none'}} onChange={(e) => {setEditValues(e.target.value)}} defaultValue={data.lyric}/> : <Box sx={{fontSize:25}}>{data.lyric}</Box>
                      }
             {!data.time? <Button sx={{padding:'0'}} onClick={()=>{
                  HandleTiming(index,1)
              }}>click here or tap tha spacebar when the singer sholud sing the file</Button>:''}
            </Box>
                    </Grid>
                    <Grid item md={3}>
                      {
                        edit ? 
                        <Grid container>
                          <Grid item md={3}>
                          </Grid>
                            <Grid item md={3}>
                              {
                                editValues != 0 ?  <Box sx={{color:'gray','&:hover':{
                                  color:'#32de84'
                                }}} onClick={() => {handleEditlyric(index)}}>
                                  <CheckCircleOutlineIcon/>
                                </Box>  : ''
                              }
                             
                            </Grid>
                          <Grid item md={3}>
                          <Box onClick={() => {
                            setEdit(false)
                            setEditValues('')
                          }} sx={{color:'gray','&:hover':{
                                color:'#484848'}}}>
                                <HighlightOffIcon/>
                              </Box>
                          </Grid>
                          <Grid item md={3}>
                          </Grid>
                        </Grid> : 
                         <div  className='edit-icon' onClick={handleEditclick}>
                         <EditIcon/>
                       </div>
                      }
                    </Grid>
                </Grid>
          //   <Box sx={{display:"flex",alignItems:"center",gap:2,paddingLeft:30,boxShadow: data. time?'':1,padding:3,mt:0.5,pointerEvents:data.time?"none":""}}>
           
          //   <Box>
              
          //   </Box>
          // </Box>
           ))
          }
          
         </Box>:<Box sx={{mt:10}}>
         {/* <Box sx={{display:"flex",alignItems:"center",gap:hello ? 2 : 8, paddingLeft:30,boxShadow:hello ? '0px -21px 20px -20px rgba(0,0,0,0.45),0px 25px 20px -20px rgba(0,0,0,0.45)':'',opacity : hello ? 1 : 0.5,padding:3,mt:0.5}}>
            <Box><VolumeDownIcon fontSize='large'/></Box>
            {
              hello ? <Box sx={{fontSize:20}}>{currentTimeVaue}</Box> : ''
            }
            <Box sx={{textAlign:"left"}}>
              <Box sx={{fontSize:25}}>Start</Box>
              {
                hello ?  <Button onClick={()=>{
                  audioValue.play()
                    HandleTiming(0,currentTime)
                    setHello(false)
                }}>click here or tap tha spacebar when the singer sholud sing tha file</Button> : ''
              }
            </Box>
          </Box> */}
         {
             audio.Upload.TextfileLyrics&&     audio.Upload
             . TextfileLyrics.map((data,index)=>(
              <>
              {
                data.lyric != '\r' ? <> 
                {/* <Box sx={{display:"flex",alignItems:"center",gap:2,paddingLeft:30,boxShadow: data. time?'':1,padding:3,mt:0.5,pointerEvents:data.time&&"none"}}>
                <Box><VolumeDownIcon fontSize='large'/></Box>
               { data. startTime? <Box sx={{fontSize:25}}>{data.startTime}</Box>:<Box sx={{fontSize:20}}>{currentTimeVaue}</Box>}
               
                <Box sx={{textAlign:"left"}}>
                  <Box sx={{fontSize:25,color:data.singerType}}>{data.lyric}</Box>
                 {!data.startTime? <Button onClick={()=>{
                      HandleTiming(index,currentTime)
                  }}>click here or tap tha spacebar when the singer sholud sing tha file</Button>:''}
                </Box>
              </Box> */}
              <div style={{boxShadow: data.StartTime <= Math.floor(currentTime) && data.EndTime > Math.floor(currentTime) ?  '0px -21px 20px -20px rgba(255,255,255,0.45), 0px 25px 20px -20px rgba(255,255,255,0.45)' : ''}}>
              <Grid ref={listRef} className='lyric-file' container sx={{padding:'20px',paddingBottom:'10px',opacity: data.StartTime !== '' && data.EndTime == 0 ? 1 : data.StartTime <= Math.floor(currentTime) && data.EndTime > Math.floor(currentTime) ? 1 : 0.5 , boxShadow :data.StartTime !== '' && data.EndTime == 0 ? '0px -21px 20px -20px rgba(255,255,255,0.45), 0px 25px 20px -20px rgba(255,255,255,0.45)' : '' }}>
              <Grid item sm={1}>
                <Box sx={{display:'flex',paddingTop:'20px',gap:2}}>
                <Box ><VolumeDownIcon style={{color:'white'}} fontSize='large'/></Box>
                <Box sx={{display:'flex',marginTop:'5px'}}>
                <Box className='timeControls' sx={{visibility: data.StartTime != 0 && data.EndTime != 0 ? 'hidden' : ''}}><ArrowLeftIcon style={{color:'white'}}/></Box>
                { data.StartTime !== ''  ? <Box sx={{fontSize:20,color:'white'}}>{data.convertedTime}</Box>:<Box sx={{fontSize:20,color:'white'}}>{currentTimeVaue}</Box>}
                <Box className='timeControls' sx={{visibility: data.StartTime != 0 && data.EndTime != 0 ? 'hidden' : ''}}><ArrowRightIcon style={{color:'white'}}/></Box>
                </Box>
                </Box>
              </Grid>
              <Grid item sm={1}></Grid>
              <Grid item sm={8}>
              <Box sx={{textAlign:"left",cursor:'pointer'}} onClick={()=>{
                      HandleTiming(index,currentTime)
                      if(data.EndTime === ''){
                        scrollRef.current.scrollTop += 100
                      }
                  }}>
                  <Box sx={{fontSize:25,color:data.SingerType}}>{data.Sentence}</Box>
                 {!data.startTime? <Button sx={{color:'grey'}}  >click here or tap the spacebar when the singer sholud sing the file</Button>:''}
                </Box>
              </Grid>
              <Grid item sm={1}>
                {
                  data.StartTime != 0 && data.EndTime != 0  ? <Box className='timeControls' sx={{paddingTop:'20px',visibility: 'hidden',cursor:'pointer'}} onClick={() => {handleModuleOpen(index)}}><ReplayIcon style={{color:'white'}}/></Box> : ''
                }
              </Grid>
              <Grid item sm={1}>
                {
                 editIndex == index && edit ?
                <Box sx={{display:'flex',gap:3,paddingTop:'20px'}}>
                <Box sx={{color:'gray' ,'&:hover':{
                  color:'green'
                }}}><CheckCircleOutlineIcon/></Box>
                <Box sx={{color:'gray' ,'&:hover':{
                  color:'red'
                }}} onClick={() => {setEdit(false)}}><HighlightOffIcon/></Box>
              </Box> : 
               <Box className='lyric-edit' sx={{paddingTop:'20px'}} onClick={() => {handleEditclick(index)}}><EditIcon/></Box>
              }
              </Grid>
              </Grid>
              </div>
              </> : <Box sx={{display:"flex",alignItems:"center",gap:2,paddingLeft:30,boxShadow: data. time?'':1,padding:3,mt:0.5,pointerEvents:data.time&&"none"}}>
                <Box><VolumeDownIcon fontSize='large'/></Box>
               { data. StartTime? <Box sx={{fontSize:25}}>{data.StartTime}</Box>:<Box sx={{fontSize:20}}>{currentTimeVaue}</Box>}
               
                <Box sx={{textAlign:"left"}}>
                  <Box sx={{fontSize:25,color:data.singerType}}>♪ ♬ ♫ ♪ ♬ ♫ ♪ ♬ ♫</Box>
                 {!data.StartTime? <Button onClick={()=>{
                      HandleTiming(index,currentTime)
                  }}>click here or tap tha spacebar when the singer sholud sing tha file</Button>:''}
                </Box>
              </Box>
              }
            
          </>
           ))
          }
         </Box>
         }
      </Box>
          <Box>
         <Box><CheckCircleOutlineIcon style={{color:'green',fontSize:'60px'}}/></Box>
         <Box>Complete!</Box>
         <Box sx={{paddingTop:'10px',paddingBottom:'15px'}}>Review your timing or Click Next to finish!</Box>
         <Box sx={{display:'flex',justifyContent:'center',gap:2}}>
           <Box sx={{border:'2px solid rgb(136, 38, 251)',color:'rgb(136, 38, 251)',borderRadius:'5px'}}><Button sx={{color:'rgb(136, 38, 251)'}} onClick={handleReview}>Review Timing</Button></Box>
           <Box><Button sx={{backgroundColor:'rgb(0, 184, 24)',color:'white',width:'133px',height:'40px'}}>Next</Button></Box>
         </Box>
        </Box> 
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Box sx={{display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
          <Box><ReplayIcon style={{color:'rgb(138, 135, 136)',fontSize:'30px'}}/></Box>
         </Box>
         <Box sx={{display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
          <Box><Typography sx={{color:'rgb(61, 56, 57)',fontSize:'1em',fontWeight:'bold'}}>Redo Timing</Typography></Box>
         </Box>
         <Box sx={{display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
          <Box><Typography sx={{color:'rgb(138, 135, 136)',marginTop:"10px"}}>Re-timing this line will erase the timimg <br/> for all lines below it.Are you sure?</Typography></Box>
         </Box>
         <Box sx={{display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center',gap:1,marginTop:'10px'}}>
          <Box><Button sx={{color:'black',background:'rgb(240, 240, 240)',height:'30px',width:'120px'}} onClick={handleClose}>no</Button></Box>
          <Box><Button sx={{color:'black',background:'rgb(0, 211, 123)',height:'30px',width:'120px'}} onClick={hanldeResetTiming}>Yes</Button></Box>
         </Box>
        </Box>
      </Modal>
          </Card>
         
        </Container>
        </Box>
  )
}

export default Songtiming