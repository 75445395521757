import React,{useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Container,Card } from "@mui/material";
import axios from "axios";
import { url } from "../../config/config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './joinlist.css';
import ProfileTimeAgo from "../../components/profileTimestamps";
import logo from './logo1.jpeg';
import playStore from './Play-Store-icon.png';
import Apple from './apple.png'
import { FaFastBackward } from "react-icons/fa";
import { FaBackwardFast, FaForwardFast } from "react-icons/fa6";
import { CiHeart } from "react-icons/ci";
import { CiStar } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { IoIosPause } from "react-icons/io";
const JoinList = () => {
    const params = useParams();
     const scrollRef = useRef();
     const playerRef = useRef();
     const [songData,setSongData] = useState([])
     const [joinList,setJoinList] = useState([])
     const [recomended,setRecomended] = useState([])
     const [audioTime,setAudioTime] = useState('')
     const [play,setPlay] = useState(false)
     const [icon,setIcon] = useState(false)
     const [playerData,setPlayerData] = useState({});

     const min = scrollRef.current ? scrollRef.current.min : ''
       const max = scrollRef.current ? scrollRef.current.max : ''
        const value = scrollRef.current ? scrollRef.current.value : ''
        if(scrollRef.current != null ){
         scrollRef.current.style.background = `linear-gradient(to right, #1dca7e 0%, #1dca7e ${(value-min)/(max-min)*100}%, #797979 ${(value-min)/(max-min)*100}%, #797979 100%)`
         scrollRef.current.oninput = function() {
           this.style.background = `linear-gradient(to right, #1dca7e 0%, #1dca7e ${(this.value-this.min)/(this.max-this.min)*100}%, #797979 ${(this.value-this.min)/(this.max-this.min)*100}%, #797979 100%)`
           console.log('vijay')
         };
        }

    useEffect(()=>{
        const GetProfile=async()=>{
         try {
            const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
           const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationById/${params.id}`);
           console.log(res.data.data)
           setSongData(res.data.data)
       } catch (error) {
         console.log(error)
       }
       //    
        }
        GetProfile()
      },[])

    useEffect(() => {
        const getJoinList = async () => {
            try{
                const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationJoinBySongCollaborationID/${params.id}`);
                console.log(res.data.data)
                setJoinList(res.data.data)
            }catch (error) {
                console.log(error)
            }
        }
        getJoinList()
    },[])
    useEffect(() => {
        const RecommendedList = async () => {
            try{
                const Index = await Number(params.ind)
                console.log(typeof Index)
                const res = await axios.post(`${url}/api/SongCollaboration/getSongCollaborationByJoinListByUserId/${params.userId}/${Index}`)
                console.log(res.data)
                setRecomended(res.data.data)
            }catch (error) {
                console.log(error)
            }
        }
        RecommendedList()
    },[])
    console.log(songData[0]?.karaokes && songData[0]?.karaokes[0]?.Name)
    const audioDuration = playerRef.current ? Math.floor(playerRef.current.duration) : 0;
    const handleTime = () => {
        setAudioTime(playerRef.current.currentTime)
    }

    const handleInput = async (e) => {
        setAudioTime(e.target.value)
        playerRef.current.currentTime = e.target.value;
       //  audioRef.current.play()
        // audioRef.current.pause()
    }

    const currentValue = Math.floor(audioTime);
   console.log(playerData)
    return(
        <>
         <Box className='back-image' sx={{backgroundImage:`url(${songData[0]?.CoverImageURL})`,height:{xl:'24rem',lg:'24rem',md:'16rem'}}}>
        </Box>
        <Box className='bg-text' sx={{top:{xl:'30%',lg:'30%',md:'35%'}}}>
        <Box sx={{display:'flex',flexDirection:'column',padding:'80px',textAlign:'center'}}>
                <Box sx={{display:'flex',flexDirection:'column',gap:.5}}>
                    <Box>{songData[0]?.karaokes && songData[0]?.karaokes[0]?.Name}</Box>
                    <Box sx={{fontSize:'1em',fontWeight:300}}>Collab</Box>
                </Box>
                <Box sx={{display:'flex',justifyContent:'center',marginTop:'20px',gap:2}}>
                    <Box>
                        <Box>
                            <Avatar sx={{width:'86px',height:'86px'}} src={songData[0]?.users && songData[0]?.users[0]?.ProfilePhoto}/>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column'}}>
                        <Box sx={{textAlign:'left',marginBottom:'5px'}}>{songData[0]?.users && songData[0]?.users[0]?.Name !== '' ?songData[0]?.users[0]?.Name : songData[0]?.users[0]?.Email }</Box>
                        <Box sx={{textAlign:'left',flexDirection:'column',background:'#f5f5f5',padding:'10px'}}>
                            <Box sx={{fontSize:'1em',fontWeight:700,color:'#1dca7e',padding:'3px'}}>{songData[0]?.Description}</Box>
                            <Box sx={{background:'#fff',display:'flex',gap:2}}>
                                 <Box sx={{width:'98px',background:'rgba(0, 0, 0, 0)',height:'98px',borderRadius:'0',transition:'background-color 0.5s ease',backgroundImage:`url(${songData && songData[0]?.CoverImageURL})`,backgroundSize:'cover',textAlign:'center','&:hover': {backgroundColor:'rgba(0, 0, 0, 0.5)',backgroundBlendMode:'multiply','&:hover .topPlayIcon':{display:'block'},cursor:'pointer'}}}>
                                    {/* <img style={{width:'98px',height:'98px',borderRadius:'0'}} src={songData && songData[0]?.CoverImageURL}/> */}
                                    {
                                        icon ? <Box onClick={() => {
                                            setIcon(false)
                                            playerRef.current.pause()
                                        }} className='topPlayIcon' sx={{marginTop:'25px',display:'none'}}>
                                        <IoIosPause size={25} style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'10px',borderRadius:'50%',textAlign:'center'}}/>
                                    </Box> : <Box onClick={() => {
                                        setPlayerData(songData[0])
                                            setIcon(true)
                                            setPlay(true)
                                            playerRef.current.play()
                                        }} className='topPlayIcon' sx={{marginTop:'25px',display:'none'}}>
                                        <PlayArrowIcon style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'10px',borderRadius:'50%',textAlign:'center'}}/>
                                    </Box>
                                    }
                                 </Box>
                                 <Box sx={{display:'flex',flexDirection:'column',width:'400px',gap:.5,marginTop:'15px'}}>
                                    <Box sx={{fontSize:'0.83em',fontWeight:700,color:'#2c2c2c'}}>{songData[0]?.karaokes && songData[0]?.karaokes[0]?.Name}</Box>
                                    <Box sx={{color:'#636363',fontSize:'0.83em'}}>{songData[0]?.users && songData[0]?.users[0]?.Name !== '' ?songData[0]?.users[0]?.Name : songData[0]?.users[0]?.Email }</Box>
                                    <Box sx={{display:'flex',marginTop:'5px',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex'}}>
                                           <Box><PlayArrowIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{songData && songData[0]?.NoOfPlay}</Box>
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                           <Box ><FavoriteIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{songData && songData[0]?.LikesUserID.length}</Box>
                                        </Box>
                                        <Box sx={{display:'flex',padding:'0 5px'}}>
                                           <Box><AccessTimeIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}><ProfileTimeAgo timestamp={songData && songData[0]?.createdAt}/></Box>
                                        </Box>
                                    </Box>
                                 </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>  
        </Box>
              <Container sx={{marginTop:'60px'}} maxWidth='lg'>
                <Box sx={{display:'flex',gap:2}}>
                   <Card sx={{height:'fit-content',marginBottom:'20px'}}>
                    <Box sx={{display:'flex',flexDirection:'column'}}>
                        <Box sx={{background:'#e8e8e8',color:'#000',fontSize:'0.8rem',padding:'15px',fontWeight:'bold'}}>
                            {`${joinList && joinList.length} Singers have joined this collab`}
                        </Box>
                        {
                            joinList &&  joinList.map((val,ind) => (
                                <Box sx={{display:'flex',background:'#fff',padding:'15px',gap:2}}>
                                       <Box sx={{width:'62px',background:'rgba(0, 0, 0, 0)',height:'62px',borderRadius:'0',transition:'background-color 0.5s ease',backgroundImage:`url(${val.songCollaboration && val.songCollaboration.CoverImageURL})`,cursor:'pointer',backgroundSize:'cover',textAlign:'center','&:hover': {backgroundColor:'rgba(0, 0, 0, 0.5)',backgroundBlendMode:'multiply','&:hover .playIcon' :{display:'block'}}}}>
                                        {/* <img style={{width:'62px',height:'62px',borderRadius:'0'}} src={val.songCollaboration && val.songCollaboration.CoverImageURL}/> */}
                                        {
                                            icon ? <Box onClick={() => {
                                                setIcon(false)
                                                playerRef.current.pause()
                                            }} className='topPlayIcon' sx={{marginTop:'25px',display:'none'}}>
                                            <IoIosPause size={25} style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'10px',borderRadius:'50%',textAlign:'center'}}/>
                                        </Box> :  <Box onClick={() => {
                                             setPlayerData(val.songCollaboration)
                                            setPlay(true)
                                            setIcon(true)
                                            playerRef.current.play()
                                            }} className='playIcon' sx={{marginTop:'14px',display:'none'}}>
                                        <PlayArrowIcon style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'5px',borderRadius:'50%',textAlign:'center'}}/>
                                    </Box>
                                        }
                                       </Box>
                                       <Box sx={{display:'flex',flexDirection:'column',gap:.3,width:'500px'}}>
                                             <Box sx={{color:'#2c2c2c',fontSize:'12px',fontWeight:700}}>{val.karaokes[0]?.Name}</Box>
                                             <Box sx={{color:'#636363',fontSize:'12px'}}>{`${songData[0]?.users && songData[0]?.users[0]?.Name !== '' ? songData[0]?.users[0]?.Name : songData[0]?.users[0]?.Email} + ${val.users[0]?.Name !== '' ? val.users[0]?.Name : val.users[0]?.Email}`}</Box>
                                             <Box sx={{display:'flex',marginTop:'5px',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex'}}>
                                           <Box><PlayArrowIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.songCollaboration &&  val.songCollaboration.NoOfPlay}</Box>
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                           <Box ><FavoriteIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.songCollaboration.LikesUserID &&  val.songCollaboration.LikesUserID.length}</Box>
                                        </Box>
                                        <Box sx={{display:'flex',padding:'0 5px'}}>
                                           <Box><AccessTimeIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}><ProfileTimeAgo timestamp={val.songCollaboration &&  val.songCollaboration.createdAt}/></Box>
                                        </Box>
                                    </Box>
                                       </Box>
                                </Box>
                            ))
                        }
                    </Box>
                   </Card>
                   <Box sx={{display:'flex',flexDirection:'column',gap:1}}>
                     <Card sx={{borderRadius:'0'}}>
                         <Box sx={{display:'flex',padding:'5px 10px',justifyContent:'space-between',width:'350px',paddingTop:'7px'}}>
                            <Box sx={{display:'flex',gap:1}}>
                                <Box>
                                    <img  style={{width:'2em',height:'2em',borderRadius:'0'}} src={logo}/>
                                </Box>
                                <Box sx={{display:'flex',marginTop:'6px',gap:.4}}>
                                    <Box sx={{fontSize:'.87em',color:'#636363',fontWeight:400}}>Download</Box>
                                    <Box sx={{color:'#000',fontSize:'.87em',fontWeight:'bold'}}>SparkSinger</Box>
                                </Box>
                            </Box>
                            <Box sx={{display:'flex',gap:2}}>
                                <Box>
                                <img style={{width:'2em',height:'2em',borderRadius:'0'}} src={playStore}/> 
                                </Box>
                                <Box>
                                <img style={{width:'2em',height:'2em',borderRadius:'0'}} src={Apple}/> 
                                </Box>
                            </Box>
                         </Box>
                     </Card>
                     <Card sx={{borderRadius:'0',marginBottom:'20px'}}>
                           <Box sx={{color:'#000',fontSize:'.87em',fontWeight:'bold',textAlign:'center',padding:'15px',alignItems:'center'}}>Recommendations</Box>
                           {
                             recomended && recomended.map((val,ind) => (
                                <Box sx={{display:'flex',padding:'15px',gap:2,borderTop:'1px solid #e8e8e8'}}>
                                    <Box>
                                        <img style={{width:'62px',height:'62px',borderRadius:'0'}}  src={val.CoverImageURL}/>
                                    </Box>
                                    <Box sx={{display:'flex',flexDirection:'column',width:'300px'}}>
                                        <Box sx={{color:'#2c2c2c',fontSize:'12px',fontWeight:700}}>{val.karaokes[0]?.Name}</Box>
                                        <Box sx={{color:'#636363',fontSize:'12px',marginTop:'5px'}}>{val.users[0]?.Name != '' ? val.users[0]?.Name : val.users[0]?.Email }</Box>
                                        <Box sx={{display:'flex',marginTop:'5px',justifyContent:'space-between',width:'100%'}}>
                                        <Box sx={{display:'flex'}}>
                                           <Box><PlayArrowIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.NoOfPlay}</Box>
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                           <Box ><FavoriteIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.LikesUserID[0]?.length}</Box>
                                        </Box>
                                        <Box sx={{display:'flex',padding:'0 5px'}}>
                                           <Box><AccessTimeIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}><ProfileTimeAgo timestamp={val.createdAt}/></Box>
                                        </Box>
                                    </Box>
                                    </Box> 
                                </Box>
                             ) )
                           }
                     </Card>
                   </Box>
                </Box>
              </Container>
              <Box className={play ? 'show' : 'footer'} sx={{display:'flex',flexDirection:'column'}}>
                <Box sx={{marginBottom:'6px',margin:'0'}}>
                    <input style={{margin:'0',width:'100%'}} id="joinInput" onChange={handleInput} max={audioDuration} value={currentValue} ref={scrollRef} type="range"/>
                </Box>
                <Box sx={{display:'flex',justifyContent:'space-between',background:'rgba(0, 0, 0, 0.8)',padding:'20px'}}>
                    <Box sx={{display:'flex',gap:6}}>
                        <Box sx={{display:'flex',gap:2,marginTop:'6px'}}>
                             <Box sx={{marginTop:'9px'}}>
                                <FaBackwardFast style={{fontSize:'20px',color:'white'}}/>
                             </Box>
                             {
                                icon ?  <Box onClick={() => {
                                    setIcon(false)
                                    playerRef.current.pause()
                                    }}>
                                <IoIosPause style={{fontSize:'35px',color:'white'}}/>
                             </Box> :     <Box onClick={() => {
                                setIcon(true)
                                playerRef.current.play()
                                }}>
                                <PlayArrowIcon style={{fontSize:'35px',color:'white'}}/>
                             </Box>
                             }
                             <Box sx={{marginTop:'9px'}}>
                                <FaForwardFast style={{fontSize:'20px',color:'white'}}/>
                             </Box>
                        </Box>
                        <Box sx={{display:'flex',gap:1}}>
                            <Box>
                                <img style={{width:'42px',height:'42px',borderRadius:'0'}} src={Apple}/>
                            </Box>
                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                <Box sx={{color:'white',fontSize:'1em',fontWeight:700}}>{'vijay'}</Box>
                                <Box sx={{color:'#636363',fontWeight:400}}>{`${joinList && joinList.length} collabs`}</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <Box sx={{display:'flex',padding:'8px',gap:1}}>
                            <Box>
                                <CiHeart style={{fontSize:'30px',color:'white'}}/>
                            </Box>
                            <Box>
                                <CiStar style={{fontSize:'30px',color:'white'}}/>
                            </Box>
                            <Box>
                                <IoShareSocialOutline style={{fontSize:'30px',color:'white'}}/>
                            </Box>
                        </Box>
                        <Box sx={{padding:'10px'}}>
                            <HiMiniSpeakerWave  style={{fontSize:'30px',color:'white'}}/>
                        </Box>
                        <Box sx={{padding:'10px',color:'white',fontWeight:500,fontSize:'1em',paddingTop:'14px'}}>00:30 / 02:00</Box>
                    </Box>
                </Box>
                <audio autoPlay ref={playerRef} controls={false} onTimeUpdate={handleTime} src={playerData && playerData.MixedSongURL}/>
              </Box>
        </>
    )
}

export default JoinList;