import { Box, Typography } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import SongGroup from '../../../data/song-group';
const Grouplist = () => {
  return (
   <Box sx={{display:"grid",gridTemplateColumns:{
    lg:"auto auto auto auto ",md:"repeat(4, auto)",sm:"repeat(3, auto)",xs:"repeat(1, auto)"
   },padding:{
    md:5,sm:0
   },justifyContent:{
    md:"space-evenly",sm:"space-evenly",xs:"center"
   }}}>
     <Box>
        <Box sx={{
            width:"200px",
            height:"150px",
            backgroundColor:"#F0F6FF",
            display:"flex",justifyContent:"center",
            alignItems:"center"
        }}><AddIcon sx={{width:"48px",height:"48px",color:"#000052"}}/></Box>
        <Box>
            <Typography fontSize={16} fontWeight={700} >Create group</Typography>
        </Box>
    </Box>
    {
        SongGroup && SongGroup.map((data,index)=>(
            <Box>
            <Box sx={{
                width:"200px",
                height:"150px",
                
                display:"flex",justifyContent:"center",
                alignItems:"center"
            }}><img src={data.singImg}></img>
                </Box>
            <Box>
                <Typography fontSize={14} color='#000052' >{data.groupName}</Typography>
            </Box>
            <Box>
                <Typography fontSize={10} color='#AAADB4' >{data.members}members</Typography>
            </Box>
            <Box>
                <Typography fontSize={10} color='#AAADB4' >{data.type}</Typography>
            </Box>
        </Box>
        ))
    }
 

   
  
   </Box>
  )
}

export default Grouplist