import { Box, Button, Container, Typography,Card,CardMedia} from "@mui/material";
import React, { useEffect, useState } from "react";
import PublicIcon from '@mui/icons-material/Public';
import img from './gift-removebg-preview.png';
import img1 from './5988768b5dce60de1522e3f28d69093d.jpg';
import { useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../../../config/config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteIcon from '@mui/icons-material/Favorite';

const UserPlaylist = () => {
    const params = useParams()
    const songs = [
        {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'vijaykumar',
            Artist : 'Yuvan Shankar raja'
        }, {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'Ranjith',
            Artist : 'Harris jayaraj'
        }, {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'vijaykumar',
            Artist : 'Yuvan Shankar raja'
        },
    ]
    const [playlistSongs,setPlaylistSongs] = useState([])
    useEffect(() => {
        const GetPlaylist = async () => {
            try{
                const res = await axios.get(`${url}/api/SongCollaborationPlayList/getSongCollaborationPlayListById/${params.id}`)
                console.log(res.data.data)
                setPlaylistSongs(res.data.data)
            }catch (error){
              console.log(error)
            }
        }   
        GetPlaylist()
    },[])
    console.log(playlistSongs)
    return(
        <Box>
            <Box sx={{background:'rgb(255, 255, 255)',marginTop:0}}>
            <Container maxWidth='lg'>
                <Box sx={{display:'flex',paddingTop:'30px',justifyContent:'space-between',paddingBottom:'30px'}}>
                    <Box>
                        <Typography sx={{fontSize:'32px',fontWeight:700,color:'rgb(45, 40, 103)'}}>Favorites</Typography>
                        <Box sx={{display:'flex',gap:.5,marginBottom:'10px'}}>
                            <Box><PublicIcon style={{fontSize:'14px',color:'rgb(114, 140, 166)'}}/></Box>
                            <Box>
                                <Typography sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)'}}>Public</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Box sx={{width:'24px',height:'24px',borderRadius:'50%',alignItems:'center'}}>
                                <img src={img}/>
                            </Box>
                            <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(45, 40, 103)'}}>vijay</Box>
                        </Box>
                        <Box sx={{display:'flex',gap:1.5,marginTop:'20px'}}>
                            <Box sx={{display:'flex',cursor:'pointer',background:'rgb(108, 46, 255)',color:'white',padding:'10px',textAlign:'center',borderRadius:'5px',gap:1,paddingBottom:'5px',paddingRight:'15px',fontSize:'17px',fontWeight:700}}>
                                <Box><PlayArrowIcon/></Box>
                                <Box>Play</Box>
                            </Box>
                            <Box sx={{display:'flex',cursor:'pointer',background:'rgb(45, 40, 103)',color:'white',padding:'10px',textAlign:'center',borderRadius:'5px',gap:1,paddingBottom:'5px',paddingRight:'15px',fontSize:'17px',fontWeight:700}}>
                                <Box><ReplyIcon/></Box>
                                <Box>Share</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:'200px',height:'200px',borderRadius:'2px'}}>
                        <img src={img1}/>
                    </Box>
                </Box>
            </Container>
            </Box>
            <Container maxWidth='lg'>
                <Box sx={{marginTop:'20px'}}>
                    <Typography sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{playlistSongs.length} recordings</Typography>
                </Box>
                <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',marginTop:'10px'}}>
{
    playlistSongs && playlistSongs.map((data,index)=>(
       <Button  style={{textDecoration:"none"}}>
        <Box sx={{margin:2}}>
            {
             data.songcollaborations &&  data.songcollaborations.map((val,ind) => (
<Box><Card key={index} sx={{ width: 178,borderRadius:'10px',mb:1}}>
      <CardMedia
        sx={{ height: 178}}
        image={val.CoverImageURL}
      />
    
    </Card></Box>
                ))
            }
<Box sx={{textOverflow:"ellipsis"}}>
    {
      data.songcollaborations &&  data.songcollaborations.map((val,ind) => (
            <Box
 
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width:178,
              color:"#2D2867",
              fontWeight:800,
              textAlign:'left'
            }}
          >{val.Description} 
          </Box>
        ))
    }
{
   data.users && data.users.map((val,ind) => (
        <>
        <Box  sx={{fontSize:"13px",color:"#2D2867",textAlign:'left',width:178,overflow:'hidden',textOverflow:'ellipsis'}} >{val.Email}</Box>
        </>
    ))
}
 <Box sx={{display:'flex'}}>
    {
       data.songcollaborations&& data.songcollaborations.map((val,ind) => (
            <>
            <Box sx={{display:'flex',gap:.2}}>
                <Box>
                <PlayArrowIcon style={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:400,marginTop:'5px'}}/> <span style={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:700}}>{val.NoOfPlay}</span>
                </Box>
                <Box>
                <FavoriteIcon style={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:400,marginTop:'5px'}}/> <span style={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:700}}>{val.NoOfPlay}</span>
                </Box>
</Box>
            </>
        ))
    }
   
 </Box>
</Box>
</Box></Button>
    ))
}

   </Box>
            </Container>
        </Box>
    )
}

export default UserPlaylist;