import { Avatar, Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import girl from '../../../assest/images/girl.webp'
import { deepOrange, deepPurple } from '@mui/material/colors';
import RedoIcon from '@mui/icons-material/Redo';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import { FaEdit } from "react-icons/fa";
import ModalComponent from './module';
import { useParams } from 'react-router-dom';
const Header = ({songlyric}) => {
  console.log(songlyric)
  const [isModalOpen,setIsModalOpen] = useState(false)
  const [modalContent,setModalContnet] = useState('')
  const [modalType,setModalType] = useState('');
  const [modalLink,setModalLink] = useState('');
  const params = useParams();

  const OpenModal = (val,data,link) => {
    console.log(val,data)
    setIsModalOpen(true)
    setModalContnet(val)
    setModalType(data)
    setModalLink(link)
  }
  const closeModal = () => {
    setIsModalOpen(false)
    setModalContnet('')
    setModalType('')
  }
  return (
    <>
  <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:2,alignItems:"center"}}>
        <Box>
           {
            songlyric.length>0? <img src={songlyric[0]['CoverImageURL']} style={{width:"120px",height:"120px"}}></img>:""
           }
        </Box>
        <Box>
            {/* <Box><Typography fontSize={13} sx={{color:"#2D2867"}}>Anne-Marie</Typography></Box>
            <Box><Typography fontSize={25} sx={{color:"#2D2867"}}> Heavy</Typography></Box>
            <Box><Typography fontSize={10} sx={{color:"#2D2867"}}>Published by
ANNEMARIE

</Typography></Box> */}
<Box sx={{display:"flex",flexDirection:"column",gap:2,marginTop:4}}>
  
<Box onClick={() => OpenModal('Spread the word','share',`http://99.62.153.141:5555/allsong/${params.id}`)}><Avatar sx={{ backgroundColor:"#2D2867",width:50,height:50}}><RedoIcon/></Avatar>
<Box><Typography fontSize={16} marginLeft={1}>share</Typography></Box>
</Box>
            <Box><Avatar sx={{backgroundColor:"#6C2EFF",width:50,height:50}}><MicExternalOnIcon/></Avatar>
            <Box><Typography fontSize={16} marginLeft={1}>sing</Typography></Box>
            </Box>
</Box>
        </Box>
    </Box>
    <ModalComponent modalLink={modalLink} isOpen={isModalOpen} onClose={closeModal} content={modalContent} type={modalType}/>
    </>
  )
}

export default Header