import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function AccordionUsage({ children,accName
}) {

  return (
    <div>
      <Accordion sx={{ width: 250, boxShadow: "none", color: "white", backgroundColor: "#2D2867", }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
           sx={{fontSize:14,fontFamily:"sans-serif",color:"wh"}}
        >
          {accName} 
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#8A59FF",fontSize:13 }}>
          {children}

        </AccordionDetails>
      </Accordion>
    </div>
  );
}
