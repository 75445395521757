
const SongbookSkelton = [
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },
    {
        type : 'rounded',
        type1 : 'text',
        width : '178px',
        Height : '178px',
    },

]

export default SongbookSkelton;