import { Box, Button, Card, Container, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { useNavigate } from 'react-router-dom';
const Cropimage = () => {
  const navigate =useNavigate()
  const sticker = useSelector((state) => state)

  return (
     <Container maxWidth='lg' sx={{textAlign:"center"}}>
        <Card sx={{display:"flex",flexDirection:"column",alignItems:"center",margin:10}}>
          <Box><Typography fontSize={40}>Crop Images</Typography></Box>
          <Box><Typography fontSize={20}>Make sure your image is centered in the middle</Typography></Box>
          <Box sx={{textAlign:"center"}}>
              <Box sx={{border:"1px solid #C8C8C8",width:800,padding:3,borderRadius:2}}>
                <img src={sticker.Uploads.stickerUpload.url} style={{width:300,border:"2px dashed #9A6B6B",padding:2}}></img>
                
              </Box>
              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:2}}>
                 <Box><ZoomInMapIcon></ZoomInMapIcon></Box>
                 <Box> <input type='range' style={{width:200}}></input></Box>
                 <Box><ZoomOutMapIcon></ZoomOutMapIcon></Box>
              </Box>
              <Box pt={2}>
                  <Button variant='contained' sx={{width:200,bgcolor:"#67C646",color:"black",textAlign:"center"}} onClick={()=>{
                navigate('/uploadsticker/addinfo')
              }}>Done Cropping</Button>
              </Box>
          </Box>
        </Card>
     </Container>
  )
}

export default Cropimage