// Video.js
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlay, FaPause, FaForward, FaBackward } from "react-icons/fa";
import { IoMdPause } from "react-icons/io";
import { MdSkipNext } from "react-icons/md";
import { IoIosSkipBackward } from "react-icons/io";
import { PiSpeakerHigh } from "react-icons/pi";
import { BsGlobe } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import CustomGlobe from "../../../components/globe/globe";
import '../styles/video.css';
import { CircularProgress,Box,Badge,Avatar,Typography,Card} from "@mui/material";
import BasicMenu from "../../../components/basic-menu2";
import { useNavigate, useParams } from "react-router-dom";
import { AudioContext } from "..";
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from "react-redux";
import axios from "axios";
import {FadeLoader} from 'react-spinners'
import { url } from "../../../config/config";
import { Opacity } from "@mui/icons-material";
function Video({handleCloseUpnext,wholeData,data}) {
    const videoRef = useRef(null);
    const inputRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoTime,setVideoTime] = useState('')
    const [globe,setGlobe] = useState(false)
    const [load,setLoad] = useState(false)
    const audioRef = useContext(AudioContext);
    const navigate = useNavigate();
    const params = useParams()
    const autoPlay = useSelector((state) => state)
    const autoPlayIstrue = autoPlay.Upload.AutoPlayOn[0].isTrue;
    const [autoplay,setAutoplay] = useState(false)
    const [count,setCount] = useState(0)
    const videoKey = data[0]?.MixedSongURL || '';
    const [isLoading,setIsLoading] = useState(false)
    // const [data,setData] = useState([])
    // console.log(data,'changing')
    const maxSound = 100;

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.load(); // Reload the video when the source changes
        }
    }, [videoKey]);

    const handleTiming = () => {
         setVideoTime(audioRef.current.currentTime)
    }

    const handleSound = async (e) => {
        const volume = await Number(e.target.value) / maxSound
        audioRef.current.volume = volume
    }

    const handleInput = async (e) => {
        setVideoTime(e.target.value)
        audioRef.current.currentTime = e.target.value;
        // setPlay(false)
        // audioRef.current.pause()
    }

    // useEffect(()=>{
    //     const GetProfile=async()=>{
    //      try {
    //        const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationById/${params.id}`);
    //        setData(res.data.data)
    //        console.log(res.data.data,'data')
    //    } catch (error) {
    //      console.log(error)
    //    }
    //    //    
    //     }
    //     GetProfile()
    //   },[params.id])

    function findNextObjectById(array, id) {
        let index = array.findIndex(obj => obj._id === id);
        if (index !== -1 && index < array.length - 1) {
            return array[index + 1];
        } else {
            return null; // Next object not found
        }
    }

    function findPreviousObjectById(array, id) {
      let index = array.findIndex(obj => obj._id === id);
      if (index !== -1 && index < array.length - 1) {
          return array[index - 1];
      } else {
          return null; // Next object not found
      }
  }


      let nextObject = findNextObjectById(wholeData,params.id)
      let prevoiusObject = findPreviousObjectById(wholeData,params.id)

    const min = inputRef.current ? inputRef.current.min : ''
const max = inputRef.current ? inputRef.current.max : ''
 const value = inputRef.current ? inputRef.current.value : ''
 if(inputRef.current != null ){
  inputRef.current.style.background = `linear-gradient(to right, #C0CCD8 0%, #C0CCD8 ${(value-min)/(max-min)*100}%, rgba(236, 232, 255, 0.4) ${(value-min)/(max-min)*100}%, rgba(236, 232, 255, 0.4) 100%)`
  inputRef.current.oninput = function() {
    this.style.background = `linear-gradient(to right, #C0CCD8 0%, #C0CCD8 ${(this.value-this.min)/(this.max-this.min)*100}%, rgba(236, 232, 255, 0.4) ${(this.value-this.min)/(this.max-this.min)*100}%, rgba(236, 232, 255, 0.4) 100%)`
  };
 }

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
        setIsPlaying(!isPlaying);
    };

    const handleForward = () => {
         if(nextObject !== null){
            navigate(`/record/${nextObject._id}`)
         }
    };

    const handleBackward = () => {
        if(prevoiusObject !== null){
            navigate(`/record/${prevoiusObject._id}`)
         }else{
            audioRef.current.currentTime = 0
         }
    };

    const str_pad_left = (string,pad,length) => {
        return (new Array(length+1).join(pad)+string).slice(-length);
      }

    const audioDuration = audioRef.current ? Math.floor(audioRef.current.duration) : '';

const currentValue = Math.floor(videoTime);
const minutes = Math.floor(audioDuration / 60);
const seconds = Math.floor(audioDuration - minutes * 60);
const currentTimeVaue = str_pad_left(minutes,'0',2) + ':' + str_pad_left(seconds,'0',2);
const currentminutes = Math.floor(videoTime/ 60);
const currentSeconds = Math.floor(videoTime - currentminutes * 60)
const currentRunning =  str_pad_left(currentminutes,'0',2) + ':' + str_pad_left(currentSeconds,'0',2);
// console.log(currentValue,currentTimeVaue,currentRunning)

  const handleGlobe = (val) => {
    setGlobe(val)
  }

  const handlePlayer = () => {
    console.log('play')
    setLoad(true)
    audioRef.current.play()
  }

  useEffect(() => {
    if(currentRunning === currentTimeVaue){
        setAutoplay(true)
        setCount(5)
    }
  },[currentRunning])

  useEffect(() => {
    if( currentTimeVaue === currentRunning && autoPlayIstrue === true && nextObject !== null){
      const interval = setTimeout(() => {
        navigate(`/record/${nextObject._id}`)
        setAutoplay(false)
      },5000)
      return () => clearTimeout(interval)
    } else if (nextObject !== null) {
      const interval = setTimeout(() => {
        setAutoplay(false)
      },5000)
      return () => clearTimeout(interval)
    }
  },[currentRunning,autoPlayIstrue])

  useEffect(() => {
    if(currentTimeVaue === currentRunning){
      const interval = setInterval(() => {
        setCount(count -1 )
      },1000)
      return () => clearInterval(interval)
    }
  },[count,currentRunning])

// console.log(autoplay)
// console.log(data[0]?.MixedSongURL,'url')
    return (
        <div style={{display:'flex',flexDirection:'column'}}>
            <Box className='userDetailAlign'>
            <Box className='songInfoTop' sx={{ display: "none", justifyContent:"space-between"}}>
                        <Box sx={{ display: "flex" ,gap:1}}>
                            {/* <Box>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <Avatars
                                            sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10 }}
                                            alt="Remy Sharp"
                                            src="/broken-image.jpg"
                                        >
                                            VIP
                                        </Avatar>
                                    }
                                >
                                    <Avatar alt="Travis Howard" src={man} />
                                </Badge>
      
                            </Box> */}
                            <Box >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10 }}
                                            alt="Remy Sharp"
                                            src="/broken-image.jpg"
                                        >
                                            Pro
                                        </Avatar>
                                    }
                                >
                                    <Avatar alt="Travis Howard" src={data[0]?.users[0].length != 0 ? data[0]?.users[0]?.ProfilePhoto : ''} />
                                </Badge>
                            </Box>
                            <Box>
                                <Box>
                                    <Typography sx={{ fontSize:{lg:14,md:10},marginTop:'10px'}}>{data[0]?.users[0]?.length != 0 ? data[0]?.users[0].Name : 'vijaykumar'} </Typography>
      
                                    {/* <Typography sx={{fontSize:{lg:14,md:10} }}>VarinderBawa786</Typography> */}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <BasicMenu id={data[0]?.users && data[0]?.users[0]?._id} value={data[0]?.karaokes && data[0].karaokes[0]?._id}/>
                        </Box>
                    </Box>
            </Box>
   <div className="centered-container">
            <div className="video-container" style={{opacity:load === false ? 0.5 : ''}}>
            <video
            poster={data[0]?.CoverImageURL}
                onCanPlay={handlePlayer}
                onClick={() => {
                    globe ? setGlobe(false) : setGlobe(false)
                }}
                    onTimeUpdate={handleTiming}
                    ref={audioRef}
                    className="video-element"
                    controls={false}
                    autoPlay={true}
                    style={{padding: globe ? '10px' : '' ,borderRadius:globe ? '10px' : '5px',width : globe ? '84px' : '',height : globe ? '84px' : '' , zIndex : globe ? 10 : '',position : globe ? 'absolute' : '',paddingTop: globe ?'380px' : '',cursor: globe ? 'pointer' : ''}}
                > 
                    <source src={data && data[0]?.MixedSongURL} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {
                    globe ? <CustomGlobe handlepLay={handleGlobe}/> : <Box className='video-box'>
                        {
                            load === false ? <div className="controls-container"><FadeLoader size="sm" color="white" /></div> : <>
                                   <div className="controls-container">
                        <div  className={isPlaying === false && currentRunning !== currentTimeVaue ? 'control-button' : 'control-pause'} onClick={handleBackward}>
                            <IoIosSkipBackward  size={30}/>
                        </div>
                        {
                            currentRunning === currentTimeVaue && currentRunning !== '00:00'? <div onClick={() => {
                                audioRef.current.currentTime = 0
                                audioRef.current.play()
                            }} style={{cursor:'pointer'}}>
                                   <AiOutlineReload size={30} color="white"/>
                            </div> :  <div className={isPlaying === false ? 'control-button' : 'control-pause'} onClick={handlePlayPause}>
                            {isPlaying ?    <FaPlay size={30}/> : <IoMdPause size={30}/>  }
                        </div>
                        }
                        <div  className={isPlaying === false  && currentRunning !== currentTimeVaue? 'control-button' : 'control-pause'} onClick={handleForward}>
                            <MdSkipNext size={40} />
                        </div>
                    </div>
                    <div className="topControls">
                        {
                            currentTimeVaue === currentRunning ? <div className={'control-pause'} style={{cursor:'pointer'}}>
                            <PiSpeakerHigh size={20} color="white"/>
                          </div> : <div style={{display:'flex',gap:2}}>
                        <div className={isPlaying === false && currentRunning !== currentTimeVaue ? 'control-button' : 'control-pause'} style={{cursor:'pointer'}}>
                            <PiSpeakerHigh size={20} color="white"/>
                          </div>
                          <div className="volume-control">
                            <input max={maxSound} min={0} onChange={handleSound} type="range" style={{width:'70px'}}/>
                          </div>
                        </div>
                        }
                          <div onClick={() => {setGlobe(true)}} className={isPlaying === false && currentRunning !== currentTimeVaue ? 'control-button' : 'control-pause'} style={{cursor:'pointer'}}>
                            <BsGlobe size={20} color="white"/>
                          </div>
                    </div>
                    {
                          currentTimeVaue === currentRunning && nextObject !== null ? <>
                             {
                              autoplay ? <Box sx={{position:'absolute',opacity:1,background:'#3C86F7',padding:'5px',color:'white',fontSize:'12px',borderRadius:'5px',left:200,bottom:75}}>{autoPlayIstrue === true ? `Coming up in ${count}` : 'Up next :'}</Box> : ''
                             }
                          </>  : ''
                        }
                    {
                        currentRunning === currentTimeVaue && nextObject !== null ?
                        <>
                        {
                             autoplay ? 
                             <div className="label1">
                             <Card sx={{margin:'30px',marginTop:'130px'}} >
                             <Box sx={{display:'flex',justifyContent:'space-between'}}>
                             <Box sx={{display:'flex',padding:'5px',gap:1}}>
                                 <Box onClick={() => navigate(`/record/${nextObject._id}`)}>
                                     <img style={{width :'60px',height:'60px',marginTop:'5px'}} src={ nextObject && nextObject.CoverImageURL}/>
                                 </Box>
                                 <Box sx={{display:'flex',flexDirection:'column',marginTop:'12px'}}>
                                     <Box sx={{color:'rgb(45, 40, 103',fontSize:'14px',fontWeight:'bold'}}>{ nextObject && nextObject.Description}</Box>
                                     <Box>{nextObject && nextObject.users[0].Email}</Box>
                                 </Box>
                             </Box> 
                             <Box  sx={{padding:'5px',cursor:'pointer'}}>
                                 <ClearIcon  style={{color:'rgb(129, 131, 140)',fontSize:'20px'}}/>
                             </Box>
                             </Box>
                         </Card> 
                             </div>
                              : ''
                          }
                        </>  :
                        <>
                          <div className="label">
                    <div className={isPlaying === false ? 'control-button' : 'control-pause'}>
                    <p style={{margin:'0',fontWeight:'bold',width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{ data[0] && data[0]?.karaokes[0]?.Name}</p>
                    <p style={{margin:'0',fontWeight:'bold',width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{data[0] && data[0]?.karaokes[0]?.Artist}</p>
            </div>
            <div className={isPlaying === false ? 'control-button' : 'control-pause'}>
              <div style={{color:'white',fontWeight:'bold'}}>{currentRunning} / {currentTimeVaue}</div>
            </div>
                    </div>
                    <div className="bar">
                    <div className={isPlaying === false ? 'control-button' : 'control-pause'}>
            <input  ref={inputRef} id="myinput" max={audioDuration} value={currentValue}  onChange={handleInput} style={{width:'100%'}} type='range'/>
           </div>
                    </div>
                        </> 
                    }
                            </>
                        }
                    </Box>
                }
            </div>
        </div>
        </div>
         
    );
}

export default Video;
