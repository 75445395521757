import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { url } from '../config/config';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CancelIcon from '@mui/icons-material/Cancel';
import { useRef,useEffect } from 'react';
import logo from '../assest/images/Bright Color-01.png'
import { Apple, Email, Face, Facebook, Google, WavingHand } from '@mui/icons-material';
import { Divider, InputBase, TextField } from '@mui/material';
import './Styles/left_Drawer.css'

const Authmodel = ({open,HandleClose}) => {
    const[email,setemail]=React.useState(null)
    const[loginEmail,setLoginEmail] = React.useState()
    const[password,setpassword]=React.useState(null)
    const[loginpassword,setLoginPassword] = React.useState()
    const[username,setusername]=React.useState(null)
    const[pagination,setPagination]=React.useState(0)
    const[show,setshow]=React.useState(true)
    const[error,seterror]=React.useState('')
    const[error1,seterror1]=React.useState('')
    const inputRef = useRef(null);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: "#2D2867",
        boxShadow: '1px 1px 20px black',
         p: 4,
         borderRadius:3,
      };
      const [scrollPosition, setPosition] =React. useState({  scrollY: 0 })
      useEffect(() => {
       function updatePosition() {
           setPosition({scrollY: window.scrollY })
       }
    
       window.addEventListener('scroll', updatePosition)
       updatePosition()
    
       return () => window.removeEventListener('scroll', updatePosition)
      }, [])
    
      
      const Signup=async()=>{
            const Data={
                Name : username,
                Email:(email?.toLowerCase()),
                Password:password
            }
        try {
            const res = await axios.post(`${url}/api/User/createUser`,Data);
            window.location.reload()
        } catch (error) {
            // Handle errors
           
           console.log(error)
        }
      }
      const Signin=async()=>{
        
        let valid = false;
        if (!loginEmail) {
            seterror("Email address is required!");
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(loginEmail)) {
            seterror("Email address is not valid!");
            valid = false;
        }
        else {
            valid = true;
            seterror("");
        }
        if (!loginpassword) {
            seterror1("Invalid Password");
            valid = false;
          } else {
            valid = true
            seterror1("");
          }
       
          if (valid) {        
            const Data={
            Name : username,
            Email:loginEmail,
            Password:loginpassword
        }
        try {
            const res = await axios.post(`${url}/api/User/login`,Data);
             console.log(res.data.data)
          window.localStorage.setItem('token',res.data.data)
         window.location.reload()
        } catch (error) {
            // if(error.message !== "Network Error"){
                
                // alert(error?.response.data.message)
            }
        //    
        //    alert(error.)
            // seterror(error.response.data.message)
        }
        }
      
    //   if(inputRef.current !== null){
    //     inputRef.current.addEventListener("keypress",function(event){
    //         if(event.key === 'Enter'){
    //             event.preventDefault();
    //             Signin()
    //         }
    //       })
    //   }
      const [passwordShow,setPasswordShow] = React.useState(false)
  return (
    
    <Modal
    open={open}
    onClose={HandleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{ backdropFilter: "blur(5px)", 
        backgroundColor: "rgb(0,0,150, 0.5)" }}
    value={scrollPosition.scrollY===0}
  >
   {
    !show ?
    <Box sx={{...style,overflowY:{lg:'scroll',xl:'hidden'},height:{lg:'450px',xl:'750px'}}}>
    <Box sx={{textAlign:"center",color:'white'}}>
    <Box onClick={HandleClose} sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }} ><CancelIcon /></Box>
    <Box sx={{ width: "250px", marginLeft: 7, display: 'flex', justifyContent: 'center' }}>
    <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto',marginLeft:80 }} />
</Box>
       <Box><Typography fontSize={40}>Signup</Typography></Box>
       <Typography>Welcome to SparkSinger<WavingHand sx={{color:"#B1A806"}}/></Typography>
       <Box sx={{display:"flex",mt:2,justifyContent:'center',position:"relative",flexDirection: "column",gap: 1, marginLeft:0,textAlign:"center",alignItems:'center',}}>
        <Box   sx={{
                    mb: 1,
                    width:"400px",height:"40px",border:1,borderColor:'#F9F1E3',borderRadius:20,display: "flex",alignItems: "center",justifyContent: "center",cursor: "pointer",
                    '&:hover': {
                       borderStyle:'solid',borderColor:'white',border:2,borderRadius:20, // Hover background color
                    }
                }}><Google sx={{display: "inline-block", marginRight: 2}}/>Continue with Google </Box>
                <Box   sx={{
                    mb: 1,
                        width:"400px",height:"40px",border:1,borderColor:'#F9F1E3',borderRadius:20,display: "flex",alignItems: "center",justifyContent: "center",cursor: "pointer",

                    '&:hover': {
                       borderStyle:'solid',borderColor:'white',border:2,borderRadius:20, // Hover background color
                    }
                }}><Facebook sx={{display: "inline-block", marginRight: 2}}/>Continue with Facebook </Box>
                <Box   sx={{
                    mb: 1,
                    width:"400px",height:"40px",border:1,borderColor:'#F9F1E3',borderRadius:20,display: "flex",alignItems: "center",justifyContent: "center",cursor: "pointer",
                    '&:hover': {
                       borderStyle:'solid',borderColor:'white',border:2,borderRadius:20, // Hover background color
                       
                    }
                }} role="button" // Improve accessibility
                tabIndex={0}><Apple sx={{display: "inline-block", marginRight: 2}}/> Continue with Apple </Box>
        </Box>
        <Divider sx={{mt:1,backgroundColor:" #999999"}}></Divider>
       <Box sx={{marginRight:19,mt:1}}><Typography fontSize={14} fontWeight={"bold"} >  Username</Typography></Box>
       
       <Box mt={.5}><InputBase  sx={{color:'white',width:220,borderRadius:'3px',paddingInlineStart:'12px',paddingBlockStart:'5px',paddingBlockEnd:'5px',border:1,borderColor:'#6f6f6f','&:hover': {
            borderStyle:'solid',borderColor:'white',border:1
         }}} 
       id="outlined-password-input"
       label='Enter your name'
       autoComplete="current-password"  value={username} onChange={(e)=>{
           setusername(e.target.value)
       }} name='username' placeholder='Enter your name'></InputBase></Box>
       <Box sx={{marginRight:23,mt:1.5}}><Typography fontSize={14} fontWeight={"bold"}>   Email</Typography></Box>
       <Box mt={.5}><InputBase   sx={{color:'white',width:220,borderRadius:'3px',paddingInlineStart:'12px',paddingBlockStart:'5px',paddingBlockEnd:'5px',border:1,borderColor:'#6f6f6f','&:hover': {
            borderStyle:'solid',borderColor:'white',border:1
         }}}
       id="outlined-password-input"
       label='Enter your email'
       className='textInputFiled'
       autoComplete="current-password" value={email} onChange={(e)=>{
           setemail(e.target.value) 
           
       }} name='email' placeholder='Enter your email'></InputBase></Box>
       <Box sx={{marginRight:19,mt:1.5}}><Typography fontSize={14} fontWeight={"bold"} >  Password</Typography></Box>
       <Box className='containerrrr'  mt={.5} ><InputBase 
       id="outlined-password-input"
       label="Password"
       className='textInputFiled'

       sx={{color:'white',width:220,borderRadius:'3px',paddingInlineStart:'12px',paddingBlockStart:'5px',paddingBlockEnd:'5px',border:1,borderColor:'#6f6f6f','&:hover': {
        borderStyle:'solid',borderColor:'white',border:1
     }}}
       autoComplete="current-password" onChange={(e)=>{
           setpassword(e.target.value)
       }} value={password} type={passwordShow ? 'text' : 'password'} name='password' placeholder='Enter your password' ></InputBase>
         {
            passwordShow ? <Box sx={{position:'absolute',right:150,bottom:165}} onClick={() => setPasswordShow(false)}><VisibilityOffIcon style={{fontSize:'20px'}}/></Box> :         <Box sx={{position:'absolute',right:150,bottom:165}} onClick={() => setPasswordShow(true)}><RemoveRedEyeIcon style={{fontSize:'20px'}}/></Box>
        }
       
       </Box>
      <Box sx={{mt:4}}><Typography fontSize={11} fontWeight={"bold"} mt={2}sx={{color:"red"}}>{error1}</Typography></Box>
       <Box mt={0}><Typography fontSize={11}>Already have an account? <span style={{cursor:"pointer",color:"white",borderBottom:'1px solid white',fontWeight:"bold"}} onClick={()=>{
        setshow(true)
       }}>Log in here</span></Typography></Box>
       <Box mt={1}>{
          <Button variant='contained' sx={{height:30,fontSize:13}} onClick={Signup}> SignUp</Button>
         
           }</Box>
           
          
            
    </Box>
   </Box>: <Box sx={{...style,overflowY:{lg:'scroll',xl:'hidden'},height:{lg:'450px',xl:'750px'}}} className='loginModal'>
     <Box sx={{textAlign:"center",color:"white"}}>
     <Box onClick={HandleClose} sx={{ display: "flex", justifyContent: "end", cursor: "pointer"}} ><CancelIcon /></Box>
       <Box sx={{width:"250px",marginLeft:12}}><img src={logo}/></Box>    
        <Box><Typography fontSize={40}>Login</Typography></Box> 
        <Typography>Welcome to SparkSinger<WavingHand sx={{color:"#B1A806"}}/></Typography>
        <Box sx={{display:"flex",mt:3,justifyContent:'center',position:"relative",flexDirection: "column",gap: 1, marginLeft:0,textAlign:"center",alignItems:'center',}}>
        <Box   sx={{
                    mb: 1,
                    width:"400px",height:"40px",border:1,borderColor:'#F9F1E3',borderRadius:20,display: "flex",alignItems: "center",justifyContent: "center",cursor: "pointer",
                    '&:hover': {
                       borderStyle:'solid',borderColor:'white',border:2,borderRadius:20, // Hover background color
                    }
                }}><Google sx={{display: "inline-block", marginRight: 2}}/>Continue with Google </Box>
                <Box   sx={{
                    mb: 1,
                        width:"400px",height:"40px",border:1,borderColor:'#F9F1E3',borderRadius:20,display: "flex",alignItems: "center",justifyContent: "center",cursor: "pointer",

                    '&:hover': {
                       borderStyle:'solid',borderColor:'white',border:2,borderRadius:20, // Hover background color
                    }
                }}><Facebook sx={{display: "inline-block", marginRight: 2}}/>Continue with Facebook </Box>
                <Box   sx={{
                    mb: 1,
                    width:"400px",height:"40px",border:1,borderColor:'#F9F1E3',borderRadius:20,display: "flex",alignItems: "center",justifyContent: "center",cursor: "pointer",
                    '&:hover': {
                       borderStyle:'solid',borderColor:'white',border:2,borderRadius:20, // Hover background color
                       
                    }
                }} role="button" // Improve accessibility
                tabIndex={0}><Apple sx={{display: "inline-block", marginRight: 2}}/> Continue with Apple </Box>
        </Box>
        <Box style={{display:"flex",marginTop:20,backgroundColor:" #999999"}}><Divider ></Divider></Box>
        <Box sx={{marginTop:2}}>
        <Box sx={{marginRight:23}}><Typography fontSize={14} fontWeight={"bold"} > Email</Typography></Box>
        <Box sx={{mt:.5}} ><InputBase  className='SingInIput' sx={{color:'white',width:220,borderRadius:'3px',paddingInlineStart:'12px',paddingBlockStart:'5px',paddingBlockEnd:'5px',border:1,borderColor:'#6f6f6f','&:hover': {
                       borderStyle:'solid',borderColor:'white',border:1
                    }}} value={loginEmail} onChange={(e)=>{
            setLoginEmail(e.target.value)
        }} 
        label="Enter your email"
        name='email' placeholder='Enter your email'></InputBase></Box>
        <Box mt={1}>
            <Box><Typography fontSize={11} sx={{ color: "red", display: "flex", justifyContent: "start", position:"relative",left:115}}>{error}</Typography></Box> 
            <Box sx={{marginRight:19}}><Typography fontSize={14} fontWeight={"bold"}> Password</Typography></Box>
        <Box sx={{mt:1}}>  <InputBase  onKeyDown={(e) => {
            if(e.key === 'Enter'){
                Signin()
            }
        }} id="outlined-password-input"
        label="Password"
        className='textInputFiled'

        autoComplete="current-password"
        sx={{color:'white',width:220,borderRadius:'3px',paddingInlineStart:'12px',paddingBlockStart:'5px',paddingBlockEnd:'5px',border:1,borderColor:'#6f6f6f','&:hover': {
            borderStyle:'solid',borderColor:'white',border:1
         }}}
          onChange={(e)=>{
           setLoginPassword(e.target.value)
        }} value={loginpassword} type={passwordShow ? 'text' : 'password'} name='password' placeholder='Enter your Password' 
></InputBase></Box>
        {
            passwordShow ? <Box sx={{position:'absolute',right:150,bottom:215}} onClick={() => setPasswordShow(false)}><VisibilityOffIcon style={{fontSize:'20px'}}/></Box> :<Box sx={{position:'absolute',right:150,bottom:215}} onClick={() => setPasswordShow(true)}><RemoveRedEyeIcon style={{fontSize:'20px'}}/></Box>
        }
        <Box><Typography fontSize={11} sx={{ color: "red", display: "flex",  position:"relative",left:115,bottom:-28}}>{error1}</Typography> </Box>
        </Box>
        {/* <Box><Typography fontSize={11} sx={{color:"red"}}>{error}</Typography></Box> */}
        <Box mt={5}><Typography fontSize={11}>Don't have an account? <span style={{cursor:"pointer",color:"white",borderBottom:'1px solid white',fontWeight:'bold'}} onClick={()=>{
        setshow(false)
       }}>Sign up for SparkSinger</span></Typography></Box>
        <Box mt={2}>
            
        <Box sx={{ display: 'flex', justifyContent: "center", gap: 1, }}>
        <Button variant='contained' sx={{ height: 30, fontSize: 13 }} onClick={HandleClose}>Cancel</Button>
        <Button variant='contained' sx={{ height: 30, fontSize: 13 }} onClick={Signin}> Login</Button>
                                    
                                </Box></Box>
              
            </Box>
            
     </Box>
    </Box>
   }
  </Modal>
  
  )
  
}

export default Authmodel