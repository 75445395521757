import React from 'react'

const About = () => {
  return (
    <div style={styles.container}>
    <h2>Privacy Policy</h2>
    <p>
      This privacy policy applies to the Spark Singer app (hereby referred to as "Application") for mobile devices that 
      was created by SoftSpark Integrated Transformation Pvt., Ltd., (hereby referred to as "Service Provider") as a 
      Commercial service. This service is intended for use "AS IS".
    </p>

    <h3>Information Collection and Use</h3>
    <p>
      The Application collects information when you download and use it. This information may include:
    </p>
    <ul>
      <li>Your device's Internet Protocol address (e.g., IP address)</li>
      <li>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</li>
      <li>The time spent on the Application</li>
      <li>The operating system you use on your mobile device</li>
    </ul>
    <p>
      The Application does not gather precise information about the location of your mobile device.
    </p>
    <p>
      The Application collects your device's location to help the Service Provider determine your approximate geographical 
      location and may use it for the following:
    </p>
    <ul>
      <li><strong>Geolocation Services:</strong> To provide features such as personalized content, relevant recommendations, and location-based services.</li>
      <li><strong>Analytics and Improvements:</strong> Aggregated and anonymized location data helps in analyzing user behavior, identifying trends, and improving overall performance and functionality.</li>
      <li><strong>Third-Party Services:</strong> Occasionally, anonymized location data may be transmitted to external services to enhance the Application.</li>
    </ul>
    <p>
      The Service Provider may use the information you provided to contact you with important information, required notices, 
      and marketing promotions.
    </p>
    <p>
      For a better experience, the Service Provider may require you to provide personally identifiable information, including 
      but not limited to Email, userId, and gender. This information will be retained and used as described in this policy.
    </p>

    <h3>Third Party Access</h3>
    <p>
      Only aggregated, anonymized data is periodically transmitted to external services to aid in improving the Application 
      and its services. The Service Provider may share your information with third parties as follows:
    </p>
    <ul>
      <li>As required by law, such as to comply with a subpoena or similar legal process</li>
      <li>When they believe in good faith that disclosure is necessary to protect rights, safety, investigate fraud, or respond to a government request</li>
      <li>With trusted service providers who work on their behalf and have agreed to adhere to this privacy policy</li>
    </ul>

    <h3>Opt-Out Rights</h3>
    <p>
      You can stop all collection of information by the Application by uninstalling it through the standard uninstall processes 
      on your device or app marketplace.
    </p>

    <h3>Data Retention Policy</h3>
    <p>
      The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time 
      thereafter. To delete User Provided Data, please contact them at <a href="mailto:raj.mahamuni@softspark.com">raj.mahamuni@softspark.com</a>.
    </p>

    <h3>Children</h3>
    <p>
      The Service Provider does not knowingly solicit or market to children under the age of 13. Parents and guardians are 
      encouraged to monitor their children's usage and ensure they do not provide personally identifiable information.
    </p>
    <p>
      If you believe a child has provided identifiable information, please contact the Service Provider at <a href="mailto:raj.mahamuni@softspark.com">raj.mahamuni@softspark.com</a>.
    </p>

    <h3>Security</h3>
    <p>
      The Service Provider is concerned about safeguarding your information and provides physical, electronic, and procedural 
      safeguards to protect information they process and maintain.
    </p>

    <h3>Changes</h3>
    <p>
      This Privacy Policy may be updated periodically. The Service Provider will notify you of any changes by updating this 
      page, and you are encouraged to consult it regularly for any changes.
    </p>

    <p>
      This privacy policy is effective as of 2024-09-30.
    </p>

    <h3>Your Consent</h3>
    <p>
      By using the Application, you consent to the processing of your information as set forth in this Privacy Policy.
    </p>

    <h3>Contact Us</h3>
    <p>
      If you have any questions regarding privacy while using the Application, or have questions about the practices, please 
      contact the Service Provider via email at <a href="mailto:raj.mahamuni@softspark.com">raj.mahamuni@softspark.com</a>.
    </p>
  </div>
);
};

const styles = {
container: {
  maxHeight: '80vh',
  overflowY: 'auto',
  padding: '20px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
  lineHeight: '1.6',
},
};
  

export default About