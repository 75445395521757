import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import songimage from '../../../assest/images/songimage.jpg'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Divider } from '@mui/material';
import recordSong from '../../../data/recorde-song';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../../config/config';
import TimeAgo from '../../recordings/timeStmaps';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { useNavigate } from 'react-router-dom';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function BasicTabs({songlyric}) {
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const [songCollab,setSongCollab] = React.useState([])
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch(newValue){
      case 1 : 
      const GetSongCollabbyId = async () => {
        try{
          const config={
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
          const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationByKaraokeID/${params.id}`,config)
          console.log(res.data.data)
          setSongCollab(res.data.data)
         }catch(error){
          console.log(error)
         }
       }
       GetSongCollabbyId()
    }
  };
console.log(songCollab)
//  console.log(songlyric[0].KaraokeLyrics)
  return (
    <Box>
      <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs  sx={{marginLeft:0,width:'100%'}} TabIndicatorProps={{style: { background: 'linear-gradient(to right , #FF0191, #1C0B4C)'}}} value={value}  onChange={handleChange} aria-label="basic tabs example">
          <Tab sx={{textTransform:"capitalize",width:'50%'}} label="Lyrics" {...a11yProps(0)} />
          <Tab sx={{textTransform:"capitalize",width:'50%'}} label="Recordings" {...a11yProps(1)} />
         
        </Tabs>
      </Box>
      {
        songlyric.length  &&<CustomTabPanel value={value} index={0}>
        {
          songlyric[0].KaraokeLyrics.map((data,index)=>(
            <Typography key={index}>{data.Sentence}</Typography>
          ))
        }
      </CustomTabPanel>
      }
      <CustomTabPanel value={value} index={1}>
     <Box sx={{width:"100%"}}>
     {
        songCollab && songCollab.map((data,index)=>(
       <>
       {
        songCollab.length !== 0 ?   <Box>
        <Box onClick={() => navigate(`/record/${data._id}`)} sx={{ display: "flex",gap: 2, alignItems: "center", fontFamily: "sans-serif",mt:2,cursor:'pointer'}}>
  
             <><Box><img src={data.CoverImageURL} style={{ width: "60px", height: "60px" }}></img></Box><Box>
               {
                 data.karaokes.map((val,ind) => (
                   <Box><Typography fontSize={12} color='#2D2867'>{data.karaokes && val.Name}</Typography></Box>
                 ))
               }
               {
                 data.users.map((val,ind) => (
                   <Box><Typography fontSize={10} color='#AAADB4'>{data.users && val.Email}</Typography></Box>
                 ))
               }
               <Box sx={{ display: "flex", gap: 1, color: "#AAADB4" }}>
                 <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                   <Box><PlayArrowIcon sx={{ fontSize: "14px" }} /></Box>
                   <Box><Typography fontSize={10}>{data.NoOfPlay && data.NoOfPlay}</Typography></Box>
                 </Box>
                 <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                   <Box><FavoriteIcon sx={{ fontSize: "12px" }} /></Box>
                   <Box><Typography fontSize={10}>{data.NoOfLikes && data.NoOfLikes}</Typography></Box>
                 </Box>
                 <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                   <Box><AccessTimeIcon sx={{ fontSize: "12px" }} /></Box>
                   <Box><Typography fontSize={10}><TimeAgo timestamp={data.createdAt}/></Typography></Box>
                 </Box>
               </Box>
             </Box>
 
             </>
 
           </Box><Divider /> 
           </Box> : <Box>
                 <Box><AudiotrackIcon/></Box>
                 <Box><Typography sx={{textTransform:'none'}}>No recordings</Typography></Box>
                 <Box><Typography>There is no</Typography></Box>
           </Box>
       }
     
          </>
         ))
        }
    
     </Box>
      </CustomTabPanel>
      </Box>

    </Box>
  );
}

