import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';

import BasicMenu from '../../../components/basic-menu3';
const Searchbar = () => {
  return (
   
    <Box sx={{display:"flex",flexDirection:{
        sm:"column",md:"row"
    } ,alignItems:"center",justifyContent:"space-around"}}>
        <Box><FormControl sx={{ m: 1 }} variant="outlined">
          
          <OutlinedInput
            id="outlined-adornment-password"
            type='fff'
            placeholder='Search'
            sx={{
                width:"55ch",
                height:"3ch"
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
             
                  edge="end"
                >
                 <SearchIcon/> 
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl></Box>
        <Box sx={{display:"flex"}}> 
        <BasicMenu/>
            
        </Box>
    </Box>
  )
}

export default Searchbar