import React, { useState } from 'react';

function TextAreaWithInputs() {
  const [lines, setLines] = useState([]);

  const handleTextAreaChange = (event) => {
    const text = event.target.value;
    const linesArray = text.split('\n');
    setLines(linesArray);
  };

  const handleInputChange = (index, value) => {
    const newLines = [...lines];
    newLines[index] = value;
    setLines(newLines);
  };

  return (
    <div>
      <textarea onChange={handleTextAreaChange}></textarea>
      <div>
        <h3>Inputs:</h3>
        {lines && lines.map((line, index) => {
          if (line) {
            return (
              <input
                key={index}
                value={line}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
            )
          }
        }
        )}
      </div>
    </div>
  );
}

export default TextAreaWithInputs;
