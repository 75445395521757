import mike from '../assest/images/mike.png'
const ImageAdd=[
    {
        id:1,
        src:mike,
        tittle1:'Millions of karaoke songs',
        tittle2:'Sing over 10+ million songs with scrolling lyrics ',
        tittle3:' and video animations!',
       
        
    },
    {
        id:2,
        src:mike,
         tittle1:'Karaoke anytime, anywhere',
        tittle2:'Sing Karaoke solo, in a duet, with a group, accapella ',
        tittle3:'or with popular artists!',
       
       
        
    },
    {
        id:3,
        src:mike,
         tittle1:'Add professional audio FX',
        tittle2:'Use studio-quality audio FX to record your',
        tittle3:' vocals and sound amazing!',
       
       
        
    }
]
export default ImageAdd;