import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import './styles/UploadSong.css'
import uploadSong from '../../data/upload-song'
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
const Uploads = () => {
  const [pathArray,setPathArray] = useState([])
  const Header = [
    {
      id : 1,
      tittle : "Lyrics",
      path : "/uploads/songlyrics"
    },
    {
      id : 2,
      tittle : "Timing",
      path : "/uploads/songtiming"
    },
    {
      id : 3,
      tittle : "Structure",
      path : "/uploads/songstructure"
    },
  ]
    const location = useLocation(); 
    const navigate = useNavigate();
    const KaraokeData = useSelector((value) => value)
    console.log(KaraokeData)
    const pathvalue = location.pathname.split('/');
    console.log(pathvalue)
    const findValue = Header.findIndex(obj => obj.path === location.pathname)
    console.log(findValue)
    // setPathArray([
    //   ...pathArray,
    //   {
    //     pathArrayInd : findValue
    //   }
    // ])
    // console.log(pathArray)
  return (
    <div>
      <div class="items--container">
        <>
         {
           KaraokeData&&KaraokeData?.Upload?.KaraokeEdited[0]?.isTrue ?  <>
            <div><Button size='small' onClick={()=>{
            navigate(-1)
        }} sx={{ color: "white",visibility:location.pathname=='/uploads/songupload'?"hidden":"visible"}} variant="outlined" startIcon={<ArrowBackIcon />}>
        Back
    </Button></div>
    {
     Header && Header.map((val,ind) => (
        <>
        {
          findValue <= ind ?   
          <div className='item'>
          <div class="content" style={{ color: val.path == location.pathname ? 'white' : "#637C74" }}>
          <div style={{ display: "flex", alignItems: "center" ,gap:'5px'}}>
              <div className='no_class'>{val.id}</div>
              <div>{val.tittle}</div>
          </div>
      </div> 
          </div> : ''
        }
        </>
     
      ))
    }
           </> :  <>
        {
    uploadSong && uploadSong.map((data,index)=>(
        <>
       {
        index==0?
        <div><Button size='small' onClick={()=>{
            navigate(-1)
        }} sx={{ color: "white",visibility:location.pathname=='/uploads/songupload'?"hidden":"visible"}} variant="outlined" startIcon={<ArrowBackIcon />}>
        Back
    </Button></div>:""
       }
        <div class='item'>
                <div class="arrow top"></div>
                <div class="content" style={{ color: data.path == location.pathname ? 'white' : "#637C74" }}>
                    <div style={{ display: "flex", alignItems: "center" ,gap:'5px'}}>
                        <div className='no_class'>{data.id}</div>
                        <div>{data.tittle}</div>
                    </div>
                </div>
                <div class="arrow bottom"></div>
            </div>
            </>
    ))
 }
        </>
         }
        </>   
</div>
   <div>
    <Outlet/>
  </div>
    </div>
  )
}

export default Uploads