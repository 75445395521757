import { Box, Card, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Notify from '../../../data/notufy-data';
import axios from 'axios';
import { url } from '../../../config/config';
const Header = () => {
    const [data,setData] = useState([])
    useEffect(() => {
        const userNotifications =  async () => {
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                  const res = await axios.get(`${url}/api/Notification/getNotificationByUserID`,config)
                  setData(res.data.data)
            }catch (error){
                console.log(error)
            }
        }
        userNotifications()
    },[])
    console.log(data)
  return (
    <Box sx={{pt:2}}>
        <Card sx={{boxShadow:'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px'}}>
            <Box sx={{padding:3}}>
                <Box><Typography sx={{display:'flex',color:'rgb(45, 40, 103)',padding:'5px'}} fontSize={25} fontWeight={600}>Notifications</Typography></Box>
            </Box>
        </Card>
    </Box>
  )
}


export default Header