import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, Modal } from '@mui/material';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import Menu_Item from '../data/menu-item';
import axios from 'axios';
import { url } from '../config/config';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import FlagIcon from '@mui/icons-material/Flag';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius : '5px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function BasicMenu({value,id}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [reportModal,setReportModal] = React.useState(false)
  const open = Boolean(anchorEl);
  const params = useParams()
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    const getUserPlaylist = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/UserPlayList/getUserPlayListByUserID/`,config)
        setPlaylist(res.data.data)
       }catch(error){
        console.log(error)
       }
     }
     getUserPlaylist()
  },[])
  React.useEffect(() => {
    const getReportTypes =  async () => {
      try{
        const response = await axios.get(`${url}/api/ReportType/getReportType`)
        console.log(response.data.data,'reportData')
      }catch (error){
         console.log(error)
      }
    }
    getReportTypes()
  },[])
  const [playlist,setPlaylist] = React.useState([])
   const handleClcik =  (ind) => {
    console.log(ind)
     switch(ind) {
         case 0 :
         const getCollab = async () => {
          try{
            const config={
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const data = await {
              UserPlayListID : playlist[0]._id,
              SongCollaborationID : params.id,
              UserID: playlist[0].UserID
            }
            const res = await axios.post(`${url}/api/SongCollaborationPlayList/createSongCollaborationPlayList`,data,config)
          } catch(error){
             console.log(error)
          }
         }
         getCollab()
          break;
          case 1 :
             navigate(`/allsong/${value}`)
             break;
             case 2 :
             navigate(`/logdetails/${value}`)
             break;
             case 6 :
             navigate(`/songcollabedit/${params.id}`)
             break
             case 4 : 
               setReportModal(true)
      }
   }
   const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
   const handleReportModal = () => {

   }
  return (
    <div>
       <Box onClick={handleClick}><MoreVertIcon/></Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{padding:'0',menuItem: {
            "&:hover": {
              backgroundColor: "rgb(69, 33, 182)"
            }
          }}}
      >
          {
            Menu_Item.length>0 && Menu_Item.map((data,index)=>(
            data.id === 2 && userTokenData.userId === id ?
        <MenuItem   sx={{padding:'0',"&hover":{backgroundColor:"rgb(99 45 229) !important"}}} onClick={handleClose}  divider={true}>
                <Box onClick={() => handleClcik(index)} sx={{display:"flex",padding:'10px',color:"#2D2867",fontSize:"15px",fontWeight:700,justifyContent:"space-between",width:"250px","&:hover": {
      backgroundColor: "rgb(99 45 229) !important",color:'white !important'
    }}}>
                <Box>{data.tittle}</Box>
                <Box>{data.icon}</Box>
            </Box>
        </MenuItem> : data.id === 1 ? <MenuItem sx={{padding:'0',"&hover":{backgroundColor:"rgb(99 45 229) !important"}}} onClick={handleClose}  divider={true}>
         <Box onClick={() => handleClcik(index)} sx={{display:"flex",padding:'10px',color:"#2D2867",fontSize:"15px",fontWeight:700,justifyContent:"space-between",width:"250px","&:hover": {
backgroundColor: "rgb(99 45 229) !important",color:'white !important'
}}}>
         <Box>{data.tittle}</Box>
         <Box>{data.icon}</Box>
     </Box>
 </MenuItem> : ''
         ))
        }
      </Menu>
      <Modal
        open={reportModal}
        onClose={handleReportModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300 }}>
         <Box sx={{textAlign:'center',color:'rgb(45, 40, 103)'}}>
          <FlagIcon color='rgb(45, 40, 103)'/>
         </Box>
         <Box sx={{fontSize:20,fontWeight:700,color:'rgb(45, 40, 103)',textAlign:'center'}}>Report</Box>
        </Box>
      </Modal>
    </div>
  );
}