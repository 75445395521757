import { Box, Container,Typography} from '@mui/material'
import React, { createContext, useState } from 'react'
import Header from './components/header'
import Songtabs from './components/song_tabs';

export const SearchContext = createContext();

const SongBook = () => {
  const [searchValue,setSearchValue] = useState('')
  return (
    <Box> 
      {/* <Box sx={{width:'100%',backgroundColor:'rgb(136, 38, 251)',height:'60px'}}>
        <Box sx={{display:'flex',justifyContent:'space-around',textAlign:'center'}}>
          <Box >
          <Typography>Download Spark Singer App</Typography>
          </Box>
        </Box>
      </Box> */}
<Box sx={{margin:5}}>
   <Container maxWidth='xl'>
    <SearchContext.Provider value={[searchValue,setSearchValue]}>
    <Header/>
   <Box>
    <Songtabs/>
   </Box>
    </SearchContext.Provider>
   </Container>
   </Box>
    </Box>
  )
}

export default SongBook 