

const uploadSong=[
    {
        id:1,
        tittle:"upload a sticker",
        path:"/uploadsticker/addimage"
    },
    {
        id:2,
        tittle:"crop image",
        path:"/uploadsticker/cropimage"
    },
    {
        id:3,
        tittle:"add info",
        path:"/uploadsticker/addinfo"
    }
]

export default uploadSong;