import React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment'; // You'll need to install this library: npm install moment

const ProfileTimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const calculateTimeAgo = () => {
            const currentTime = moment();
            const uploadTime = moment(timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
            const duration = moment.duration(currentTime.diff(uploadTime));
            
            if (duration.asSeconds() < 5) {
                setTimeAgo('just now');
            } else if (duration.asMinutes() < 1) {
                setTimeAgo(`${Math.floor(duration.asSeconds())}s`);
            } else if (duration.asHours() < 1) {
                setTimeAgo(`${Math.floor(duration.asMinutes())}m`);
            } else if (duration.asDays() < 1) {
                setTimeAgo(`${Math.floor(duration.asHours())}h`);
            } else if (duration.asDays() < 7) {
                setTimeAgo(`${Math.floor(duration.asDays())}d`);
            } else if (duration.asDays() < 30) {
                setTimeAgo(`${Math.floor(duration.asWeeks())}w`);
            } else if (duration.asDays() < 365) {
                setTimeAgo(`${Math.floor(duration.asMonths())}mo`);
            } else {
                setTimeAgo(`${Math.floor(duration.asYears())}yr`);
            }
        };

        calculateTimeAgo();

        // Calculate and update time ago every minute
        const interval = setInterval(calculateTimeAgo, 1000);

        return () => clearInterval(interval);
    }, [timestamp]);

    return <span>{timeAgo}</span>;
};

export default ProfileTimeAgo;