import { Avatar, Box, Card, Container, Divider, TextField, Typography } from '@mui/material'
import React, { useState,useEffect } from 'react'
import girl from '../assest/images/girl.webp'
import Switch from '@mui/material/Switch'; 
import axios from 'axios';
import { url } from '../config/config';
const Settings = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const[userProfileData,setUserProfileData]=useState([])
  const [userEmail,setUserEmail]=useState()
  console.log(userProfileData,"iyiuyiutvuytcurctrctrc")
  useEffect(() => {
    const GetProfileData = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`,config)
        setUserProfileData(res.data.data)
        console.log(res.data.data,'dataValue')
         const email = await res.data.data[0].Email;
        setUserEmail(email.split('@'))
      }catch (error){
         console.log(error)
      }
    }
    GetProfileData()
   },[])
   const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    // Handle password change logic here
    console.log("Password changed successfully!");
    // Clear the form
    setNewPassword('');
    setConfirmPassword('');
    setError('');
  };
  return (
    
    <Container maxWidth='lg'>
      <Card sx={{padding:2,mt:3,backgroundColor:"#2D2867",color:"white"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:4}}>
   <Box sx={{backgroundColor:"black",padding:"20px",color:"white"}}>
          <Box><Typography fontWeight={700} fontSize={20}>Settings</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:6}}>
          <Box><Typography fontWeight={600}>Profile images</Typography></Box>
          <Box><Avatar sx={{width:90,height:90}} alt="Remy Sharp" src={userProfileData[0]?.ProfilePhoto} /></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:16}}>
          <Box><Typography fontWeight={600}>Blurb</Typography></Box>
          <Box><Typography fontSize={20} fontWeight={500} color={"black"} backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Name}</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:13}}>
          <Box><Typography fontWeight={600}>Location</Typography></Box>
          <Box><Typography fontSize={20} fontWeight={500} color={"black"} backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Bio}</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
   </Box>
      </Card>
      <Card sx={{padding:2,mt:3,backgroundColor:"#2D2867",color:"white"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:4}}>
   <Box sx={{backgroundColor:"black",padding:"20px",color:"white"}}>
          <Box><Typography fontWeight={700} fontSize={20}>Login Information</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
      
        <Box sx={{display:"flex",alignItems:"center",gap:16}}>
          <Box><Typography fontWeight={600}>Name</Typography></Box>
          <Box sx={{display:"flex",gap:6}}>
            <Box>
            <Box><Typography fontSize={20} fontWeight={500} color={"black"}  backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Name}</Typography></Box>
  
            </Box>
          </Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
      
        <Box sx={{display:"flex",alignItems:"center",gap:12}}>
          <Box><Typography fontWeight={600}>Username</Typography></Box>
          <Box sx={{display:"flex",gap:6}}>
            <Box>
            <Box><Typography fontSize={20} fontWeight={500} color={"black"} backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Name}</Typography></Box>
  
            </Box>
            <Box>
           {/* <Typography fontWeight={600} color="white">https://www.SparkSinger.com/RanjithKumar2037</Typography> */}
  
            </Box>
          </Box>
          
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:16}}>
          <Box><Typography fontWeight={600}>Email</Typography></Box>
          <Box><Typography fontSize={20} fontWeight={500} color={"black"} backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Email}</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",gap:12}}>
          <Box><Typography fontWeight={600}>Password</Typography></Box>
          <Box><Typography fontSize={20} fontWeight={500} color={"black"}  backgroundColor={"white"} width={"400px"}>************</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
      
    {/* <form onSubmit={handleSubmit}>
      <Box sx={{display:"flex"}}>
        <label htmlFor="newPassword">New Password:</label>
        <input
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={handleNewPasswordChange}
          required
        />
      </Box>
      <Box sx={{display:"flex",mt:1}}>
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          required
        />
      </Box>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <button type="submit">Change Password</button>
    </form> */}
  

   </Box>
      </Card>
      <Card sx={{padding:2,mt:3,backgroundColor:"#2D2867"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:4,color:"white"}}>
   <Box sx={{backgroundColor:"black",padding:"20px",color:"white"}}>
          <Box><Typography fontWeight={700} fontSize={20}>SparkSinger Emails</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
      
      
      
       
   </Box>
   <Box sx={{display:"flex",justifyContent:"space-between",color:"white"}}>
    <Box><Typography fontWeight={600}>Receive Product Updates from SparkSinger</Typography></Box>
    <Box><Typography><Switch {...label} defaultChecked /></Typography></Box>
   </Box>
      </Card>
    </Container>
  )
}

export default Settings