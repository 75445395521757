import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box ,Typography } from '@mui/material';
import SongSelect from './auto-complete';
export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
       <Box sx={{display:"flex"}}>
       <Box><FilterListIcon/></Box>
            <Box>
                <Typography>Most Popular</Typography>
            </Box>
       </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} color='#110E39'>Most Popular</MenuItem>
        <MenuItem onClick={handleClose} color='#110E39'>most recent</MenuItem>
        <MenuItem onClick={handleClose} color='#110E39'>name A-Z</MenuItem>
        <MenuItem onClick={handleClose} color='#110E39'>name Z-A</MenuItem>
      </Menu>
    </div>
  );
}
