import { useCallback, useState } from 'react';
import { Box, Paper, Typography, IconButton, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { useSelector,useDispatch } from 'react-redux'
function DragDropFileUpload({ onFileUpload,spinner }) {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((event) => {
    console.log(event)
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        onFileUpload(event.dataTransfer.files[0]);
      }
    },
    [onFileUpload]
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files[0]) {
        onFileUpload(event.target.files[0]);
      }
    },
    [onFileUpload]
  );
  const audio = useSelector((state) => state)

  console.log(audio)
 
  const audioSrc = audio.Upload.songUpload.name
  return (
   <Box  sx={{
    border: dragOver ? '1px dashed red' : '',
    padding: 5,
    textAlign: 'center',
    cursor: 'pointer',
    background: dragOver ? '#eee' : '#fafafa',
    width:"600px"
  }}>
    {
      spinner ? <Box> 
        <Box>  <CircularProgress /></Box>
        <Box>uploading</Box>
      </Box>:
      <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
     
    >
      <input
       
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        accept="audio/mpeg"
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        <Box display="flex" flexDirection="column" gap={2} alignItems="center">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <LibraryMusicIcon style={{ fontSize: 60 }} />
          </IconButton>
          <Box>
            <Typography>{audioSrc}</Typography>
          </Box>
          <Typography sx={{backgroundColor:"#564CF2",color:'white',padding:'10px',width:'150px',borderRadius:'5px'}} type='file' variant="contained">Select audio file</Typography>
          <Box display='flex' gap='5px' paddingBottom='40px'>
            <Typography color='#9598B4'>or</Typography>
            <Typography>drag and drop it here</Typography>
          </Box>
          {/* <Typography fontSize={15} color='#120B27'><Typography color='#9598B4'>or</Typography> drag and drop it here</Typography> */}
        </Box>
        <Box sx={{display:"flex",justifyContent:"center",gap:2}}>
            <Box><Typography fontSize={14} color='#959696'>Acceptable formats:.m4a or .mp3</Typography></Box>/
            <Box><Typography fontSize={14} color='#959696'>Max size:20 MB</Typography></Box>/
            <Box><Typography fontSize={14} color='#959696'>Max length: 10 minutes</Typography></Box>
        </Box>
      </label>
    </Paper>
    }
   </Box>
  );
}

export default DragDropFileUpload;