import { useCallback, useState } from 'react';
import { Box, Paper, Typography, IconButton, Button } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import girl2 from '../assest/images/girl2.jpg'
function 





DragDropFileUpload({ onFileUpload }) {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        onFileUpload(event.dataTransfer.files[0]);
      }
    },
    [onFileUpload]
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files[0]) {
        onFileUpload(event.target.files[0]);
      }
    },
    [onFileUpload]
  );

  return (
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
       
        padding: 20,
        textAlign: 'center',
        cursor: 'pointer',
        background: dragOver ? '#eee' : '#fafafa',
        backgroundImage:`url(${girl2})`
      }}
      
    >
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        <Box display="flex" flexDirection="column" gap={2} alignItems="center">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <CollectionsIcon style={{ fontSize: 60 }} />
          </IconButton>
          <Box  variant="contained" color='white'>Select audio file</Box>
          
        </Box>
      
      </label>
    </Paper>
  );
}

export default DragDropFileUpload;