import React, { useState } from 'react'
import Player from './player'
import TextAreaWithInputs from '../../../components/text-area'
import { Box, Button, Card, Checkbox, Container, Divider, Input, Typography,Modal } from '@mui/material'
import PropTypes, { func } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import Crop54Icon from '@mui/icons-material/Crop54';
import RectangleRoundedIcon from '@mui/icons-material/RectangleRounded';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useSelector,useDispatch } from 'react-redux'
import { AddColorPalette, AddSingerProperty, Lyrics, singerTypeChoosing } from '../../../features/addlyric/addLyric';
import { useNavigate } from 'react-router-dom';
import { deleteLyrics } from '../../../features/addlyric/addLyric';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { editLyric } from '../../../features/addlyric/addLyric';
import { editSinger } from '../../../features/addlyric/addLyric';
import { SketchPicker } from 'react-color';
import Grid from '@mui/material/Grid';
import { Saturation,Hue,useColor,ColorPicker } from 'react-color-palette';
import "react-color-palette/css";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height : 600,
  bgcolor: 'black',
  border: '2px solid #000',
  borderRadius : '5px',
  boxShadow: 24,
  p: 4,
  overflow : 'scroll',
  textAlign:'center',
  overflowX : 'hidden'
};

const Songlyrics = () => {
  const [value, setValue] = React.useState(0);
const[lyric,setlyric]=useState('')
const[singer,setsinger]=useState(0)
const[lyricArray,setlyricArray]=useState([])
const value1 = useSelector((state) => state)
const EditColor = value1.Upload.colorMapping;
const [songType,setSongType] = useState('')
const [open,setOpen] = useState(false)
console.log(value1)
  const navigate=useNavigate()
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  // const [singer1Show,setSinger1Show] = useState(false)
  // const [singer]
  let nextId = 0;
  const HandleSubmit=()=>{
    setlyric('')
    
    dispatch(Lyrics( { singerIndex:singer, lyric: lyric,startTime:'',endTime:'',color:color,trackIndex:0,lyricType:0 }))
    
   
  }
   const [color,setColor] = useColor('#6699CC')
   const [singer2Color,setSinger2Color] = useColor('#3CB371')
   const [bothColor,setBothColor] = useColor('#FFAC1C')
   const [singer1Palette,setSinger1Palette] = useState(false)
   const [singer2Palette,setSinger2Palette] = useState(false)
   const [bothPalette,setBothPalette] = useState(false)
  const audio = useSelector((state) => state)

  // console.log(color,singer2Color,bothColor,EditColor[0])

  const dispatch =useDispatch()
 const HandlSubmit=()=>{
  navigate('/uploads/songtiming')
  // dispatch(Lyrics(lyricArray))
  const data ={
  color : color.hex,
  singer2Color : singer2Color.hex,
  bothColor : bothColor.hex
  }
  dispatch(AddColorPalette(data))
 }
 const [selectedValue, setSelectedValue] = React.useState('a');

 const handleChange = (event) => {
   setSelectedValue(event.target.value);
 };

  const deleteLyric=(index)=>{
    dispatch(deleteLyrics(index))
  }
  const editLyrics=(index,lyric)=>{
    const data={
      index:index,
      lyric:lyric
    }
    dispatch(editLyric(data))
  }
  const editSingers=(index,singer)=>{
    console.log(index,singer)
    const data={
      index:index,
      singer:singer
    }
    dispatch(editSinger(data))
  }
  console.log(audio.Upload.TextfileLyrics)
  const handleColorPalette = async (val,num,id) => {
    console.log(val,num,id)
      let SingerData = {
        SingerId : num,
        SingerColor : val,
        singerIndex : id
      }
      dispatch(AddSingerProperty(SingerData))
  }

  audio.Upload.TextfileLyrics.map((val,ind) => {
    if(ind == val.singerIndex){
      console.log(val.singerType)
    }
  })
  const handleFilelyricChange = (e) => {
    console.log(e.target.value.length)
    if(e.target.value.length > 40){
      alert('Lyric should not exceed 40 characters')
    }
  }

  const handleCheck = (val) => {
    setSongType(val)
    const data = {
      singerIndex : 0,
      singerType : color.hex
    }
    dispatch(singerTypeChoosing(data))
  }

  for(let i = 0 ; i > audio.Upload.TextfileLyrics.length ; i++){
    
    console.log(audio.Upload.TextfileLyrics[i].Sentence.split(' '))
  }
 console.log(localStorage.getItem('edit'))

 const handleClose = () => {
  setOpen(false)
 }

 return (
  <Container maxWidth='md'>
      <Box>
      <Box>
      <Box textAlign='center'>
        <Box><Typography fontSize={40} fontWeight={600}>Lyrics Editing</Typography></Box>
        <Box><Typography fontSize={20}>Double the fun by arranging your lyrics for singers</Typography></Box>
      </Box>
      <Box sx={{pt:2,position:"sticky",bottom:0,width:'100%'}}>
        <audio controlsList='nodownload noplaybackrate' style={{width:'100%'}} controls={true} src={audio.Upload.songUpload.url}/>
        </Box>
        <Box sx={{padding:1,mt:1,flexGrow: 1}}>
               <Grid container spacing={8}>
               <Grid item xs={4}></Grid>
               <Grid item xs={3}>
               <Button onClick={HandlSubmit} sx={{width:200}} variant='contained'>continue</Button>
               </Grid>
               <Grid item xs={5}>
               <Grid container sx={{paddingTop:'8px'}}>
              <Grid item xs={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography sx={{textAlign:'right'}}>Singer1</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div  id='default-color' onClick={() => setSinger1Palette(true)} style={{backgroundColor:color.hex,width:'35px',height:'20px',borderRadius:'2px'}}></div>
                      {
                        singer1Palette ? <div id='color-palette'>
                        <ColorPicker color={color} onChange={setColor} />
                        <Button sx={{textAlign:'right',color:'red'}} onClick={() => {setSinger1Palette(false)}}>Apply</Button>
                        <Button onClick={() => 
                          {setColor({hex:'#6699CC',rgb:{r:60,g:179,b:113,a:1},hsv:{h:146.72268907563026,s:66.4804469273743,v:70.19607843137254,a:1}})
                            setSinger1Palette(false)
                          }}>Cancel</Button>
                      </div> : ''
                      }
                           
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={4} sx={{paddingLeft:'7px'}} >
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography>Singer2</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div onClick={() => setSinger2Palette(true)} style={{backgroundColor:singer2Color.hex,width:'35px',height:'20px',borderRadius:'2px'}}></div>
                      {
                        singer2Palette ? <div id='color-palette'>
                        <ColorPicker color={singer2Color} onChange={setSinger2Color} />
                        <Button sx={{textAlign:'right',color:'red'}} onClick={() => {setSinger2Palette(false)}}>Apply</Button>
                        <Button onClick={() => {
                          setSinger2Color({hex:'#3CB371',rgb:{r:60,g:179,b:113,a:1},hsv:{h:146.72268907563026,s:66.4804469273743,v:70.19607843137254,a:1}})
                          setSinger2Palette(false)
                          }}>Cancel</Button>
                      </div> : ''
                      }
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography sx={{textAlign:'right'}}>Both</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div onClick={() => {setBothPalette(true)}} style={{backgroundColor:bothColor.hex,width:'35px',height:'20px',borderRadius:'2px'}}></div>
                      {
                        bothPalette ? <div id='color-palette'>
                        <ColorPicker color={bothColor} onChange={setBothColor} />
                        <Button sx={{textAlign:'right',color:'red'}} onClick={() => {setBothPalette(false)}}>Apply</Button>
                        <Button onClick={() => {
                          setBothColor({hex:'#FFAC1C',rgb:{r:255,g:172,b:28,a:1},hsv:{h:38.06167400881058,s:89.01960784313725,v:100,a:1}})
                          setBothPalette(false)}}>Cancel</Button>
                      </div> : ''
                      }
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
               </Grid>
               </Grid>
               <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                <Button sx={{background:'rgb(60, 179, 113)',color:'white',textTransform:'none'}} onClick={() => {setOpen(true)}}>Preview</Button>
               </Box>
               <Box sx={{display:'flex',justifyContent:'flex-end',marginTop:'20px'}}>
                <Box sx={{display:'flex'}}>
                  <Box>
                    Solo
                  </Box>
                  <Box><input type='checkbox' name='solo' checked={songType === 'solo' ? true : false} onChange={(e) => {handleCheck(e.target.name)}}/></Box>
                </Box>
                <Box sx={{display:'flex'}}>
                  <Box>
                    Duet
                  </Box>
                  <Box><input type='checkbox' name='duet' checked={songType === 'duet' ? true : false} onChange={(e) => {handleCheck(e.target.name)}}/></Box>
                </Box>
               </Box>
            </Box>
      </Box>
  {
    audio.Upload.TextfileLyrics.length ==0 ?<div>   <Box sx={{display:"flex",gap:5,alignItems:"center",pt:5}}>
        
    <Box>
        <input className='input-focus' onChange={(e)=>{setlyric(e.target.value)
        
        }} value={lyric} style={{background:"none",overflowY:"visible",width:450,height:25,border:"none"}} placeholder='Enter your lyrics here...'></input>
      </Box>
      <Box sx={{ width: '100%',textAlign:"center" }}>
     <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
     <FormControl>
  
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="female"
      name="radio-buttons-group"
    >
     <Box sx={{display:"flex"}}>
  
      <Box>
      <FormControlLabel value={0} onChange={(e)=>{
        setsinger(e.target.value)
      }} control={<Radio />} sx={{fontSize:11}}  label="singer1" />
      
      </Box>
      <Box><FormControlLabel value={1} onChange={(e)=>{
        setsinger(e.target.value)
      }} control={<Radio />} sx={{fontSize:11}} label="singer2" /></Box>
      <Box><FormControlLabel value={2} onChange={(e)=>{
        setsinger(e.target.value)
      }} control={<Radio />} sx={{fontSize:11}} label="Both" /></Box>
     </Box>
    </RadioGroup>
  </FormControl>
  <Box>
    <Button variant='contained' onClick={HandleSubmit}>Add</Button>
  </Box> 
     </Box>
   
    
  </Box> 
 
 
    </Box>
    <Divider/>
<Box >
   {
      audio.Upload
      . songLyrics  && audio.Upload
      . songLyrics .map((data,index)=>(
    < >
      <Box sx={{display:"flex",gap:6,alignItems:"center"}}>
  
  <Box sx={{textAlign:"center"}}>
  <Box><input style={{overflowY:"visible",width:450,height:25,border:"none",color:data.color}} onChange={(e)=>{
    editLyrics(index,e.target.value)
  }} defaultValue={data.lyric} ></input></Box>
  </Box>
  <Box >
  <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <Box>
      <FormControlLabel value={0} checked={data.singerIndex==0} onChange={(e)=>{
        editSingers(index,e.target.value)
      }} control={<Radio />} sx={{fontSize:2}}  label="singer1" />
      </Box>
      <Box><FormControlLabel value={1}checked={data.singerIndex==1} onChange={(e)=>{
        editSingers(index,e.target.value)
      }} control={<Radio />} sx={{fontSize:2}} label="singer2" /></Box>
      <Box><FormControlLabel value={2} checked={data.singerIndex==2} onChange={(e)=>{
        editSingers(index,e.target.value)
      }} control={<Radio />} sx={{fontSize:2}} label="Both" /></Box>
       <Box>
    <Box variant='contained' sx={{cursor:"pointer",color:"red"}} onClick={()=>{
      deleteLyric(index)
    }} ><DeleteForeverIcon/></Box>
  </Box> 
     </Box>
     
  </Box>
  


</Box>
    </>
 ))
}

   </Box> </div>:<><Box sx={{mt:6}}>
            <Box sx={{overflowY:"scroll",height:350,textAlign:"center"}}>
              {audio.Upload.TextfileLyrics.map((data,index) => ( 
              <>
                {
                  data.lyric != '\r' ? 
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>

                  <Box key={index} sx={{ width: 530 }}>
                    <input onChange={handleFilelyricChange} className='lyric-input' style={{color:data.SingerType,fontWeight:'bold'}} defaultValue={data.Sentence}></input></Box>
                    {
                      songType != '' ?   <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel value={color.hex != '#6699CC' ? color.hex : '#6699CC'} checked={songType === 'solo' ? true : false} onChange={(e) => {
                          handleColorPalette(e.target.value,index,0)
                        }} control={<Radio />} label="Singer 1" />
                        <FormControlLabel onChange={(e) => {
                          handleColorPalette(e.target.value,index,1)
                        }} value={singer2Color.hex != '#3CB371' ? singer2Color.hex : '#3CB371'} control={<Radio />} label="Singer 2" />
                        <FormControlLabel onChange={(e) => {
                          handleColorPalette(e.target.value,index,2)
                        }} value={bothColor.hex != '#FFAC1C' ? bothColor.hex : '#FFAC1C'} control={<Radio />} label="Both" />
                      </RadioGroup>
                    </FormControl> : <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel value={color.hex != '#6699CC' ? color.hex : '#6699CC'}  onChange={(e) => {
                          handleColorPalette(e.target.value,index,0)
                        }} control={<Radio />} label="Singer 1" />
                        <FormControlLabel onChange={(e) => {
                          handleColorPalette(e.target.value,index,1)
                        }} value={singer2Color.hex != '#3CB371' ? singer2Color.hex : '#3CB371'} control={<Radio />} label="Singer 2" />
                        <FormControlLabel onChange={(e) => {
                          handleColorPalette(e.target.value,index,2)
                        }} value={bothColor.hex != '#FFAC1C' ? bothColor.hex : '#FFAC1C'} control={<Radio />} label="Both" />
                      </RadioGroup>
                    </FormControl>
                    }
                
                </Box>  : <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>

<Box key={index} sx={{ width: 530 }}>
  <input onChange={handleFilelyricChange} className='lyric-input' style={{color:data.SingerType}} defaultValue='♪ ♬ ♫ ♪ ♬ ♫ ♪ ♬ ♫'></input></Box>
<FormControl>
<RadioGroup
row
aria-labelledby="demo-row-radio-buttons-group-label"
name="row-radio-buttons-group"
>
<FormControlLabel  value={color.hex != '#6699CC' ? color.hex : '#6699CC'} onChange={(e) => {
handleColorPalette(e.target.value,index,0)
}} control={<Radio />} label="Singer 1" />
<FormControlLabel onChange={(e) => {
handleColorPalette(e.target.value,index,1)
}} value={singer2Color.hex != '#3CB371' ? singer2Color.hex : '#3CB371'} control={<Radio />} label="Singer 2" />
<FormControlLabel onChange={(e) => {
handleColorPalette(e.target.value,index,2)
}} value={bothColor.hex != '#FFAC1C' ? bothColor.hex : '#FFAC1C'} control={<Radio />} label="Both" />
</RadioGroup>
</FormControl>
</Box>
                }
                </>
              ))}
            </Box>
          </Box>
              
            </>
  }
    </Box> 
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='lyric-preview' sx={style}>
          {
            audio && audio.Upload.TextfileLyrics.map((data,ind) => (
              <Box sx={{color:data.SingerType,marginBottom:'3px'}}>{data.Sentence}</Box>
            ))
          }
        </Box>
      </Modal> 
  </Container>
  )
}

export default Songlyrics