import { Box, Button, Skeleton, Stack } from '@mui/material'
import React, { useState,useEffect } from 'react'

import Card from '@mui/material/Card';
import Coverimage from '../../../assest/images/girl.webp'
import FadeLoader from 'react-spinners/FadeLoader';

import CardMedia from '@mui/material/CardMedia';

import { Navigate, useNavigate } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import SongbookSkelton from '../../../data/songbook-skelton';
const SongCard = ({songs}) => {
 const navigate =useNavigate()
 const [pagination,setPagination]= useState(0)
//  songs.map((val,ind) => {
//   console.log(val.metaData)
//  })
useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight; 
    if (scrollTop + windowHeight >= documentHeight) {
      console.log('EverOne Incremant')
      setPagination(prevPagination => prevPagination + 1)
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
const [color,setColor] = useState('gray')
  return ( 
    <>
    {
      songs.length !== 0 ?   <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left'}}>
      {
         songs&& songs.map((data,index)=>(
             <Button onClick={()=>{
              navigate(`/allsong/${data._id}`,{
                state:data._id
              })
             }} style={{textDecoration:"none"}}>
              <Box sx={{margin:2}}>
      <Box><Card key={index} sx={{ width: 178,borderRadius:'10px',mb:1}}>
            <CardMedia
              sx={{ height: 178}}
              image={data.CoverImageURL? data.CoverImageURL:Coverimage}
              title={data.Artist}
            />
          
          </Card></Box>
      <Box sx={{textOverflow:"ellipsis"}}>
      <Box
       
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width:178,
                color:"#2D2867",
                fontWeight:800,
                textAlign:'left'
              }}
            >{data.Name} 
            </Box>
          <Box  sx={{fontSize:"13px",color:"#2D2867",textAlign:'left',width:178,overflow:'hidden',textOverflow:'ellipsis'}} >{data.Artist}</Box>
          <Box sx={{fontSize:"12px",color:"#585A64",textAlign:'left',width:178,overflow:'hidden',textOverflow:'ellipsis'}}>{data.Name}</Box>
      </Box>
      </Box></Button>
          ))
      }
      
         </Box> : <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',gap:6}}>
              {
                SongbookSkelton && SongbookSkelton.map((val,ind) =>(
                  <Box>
                    <Stack>
                    <Skeleton sx={{marginBottom:'10px'}} animation='wave' variant={`${val.type}`} width={val.width} height={val.Height} />
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    </Stack>
                  </Box>
                ))
              }
    </Box> 
    }
 
   </>
  )
}

export default SongCard